import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  listLoading: false,
  actionsLoading: false,
  error: null,
  menus: [],
  subMenus:[],
  permitedFeatures: [],
  permitedFeaturesOnRolePrev: [],
  permitedFeaturesOnRole: [],
  roles: [],
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const featurePermissionsSlice = createSlice( {
  name: "featurePermission",
  initialState: initialState,
  reducers: {
    catchError: ( state, action ) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if ( action.payload.callType === callTypes.list )
      {
        state.listLoading = false;
      } else
      {
        state.actionsLoading = false;
      }
    },
    startCall: ( state, action ) => {
      state.error = null;
      if ( action.payload.callType === callTypes.list )
      {
        state.listLoading = true;
      } else
      {
        state.actionsLoading = true;
      }
    },
    setMenus: (state, action)=>{
      state.actionsLoading = false;
      state.menus = action.payload;
      state.error = null;
    },
    setSubMenus: (state, action)=>{
      state.actionsLoading = false;
      state.subMenus = action.payload;
      state.error = null;
    },
    setPermitedFeaturesMenuWise: (state, action)=>{
      state.actionsLoading = false;
      state.permitedFeatures = action.payload;
      state.error = null;
    },
    setPermitedFeaturesMenuWiseByRole: (state, action)=>{
      state.actionsLoading = false;
      state.permitedFeaturesOnRolePrev = action.payload;
      state.permitedFeaturesOnRole = action.payload;
      state.error = null;
    },
    updatePermetedFeaturesByRole: (state, action)=>{
      state.permitedFeaturesOnRole = action.payload;
    },
    actionDone: (state, action)=>{
      state.actionsLoading = false;
      state.error = null;
    },
    clearPermission:  (state, action)=>{
      state.permitedFeatures = [];
      state.actionsLoading = false;
      state.error = null;
    },
    updatePermission:  (state, action)=>{
      state.permitedFeatures = action.payload.permission;
    },
    setRoles:  (state, action)=>{
      state.roles = action.payload.roles;
    }
  }
} );

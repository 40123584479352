import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  touched,
  errors,
  label,
  isRequired = false,
  withFeedbackLabel = false,
  customFeedbackLabel,
  onChange,
  type = "text",
  value = "",
  disabled = false,
  ...props
}) {
  return (
    <div className="form-group">
      {label && <label className={isRequired ? "required" : ""}>{label}</label>}
      <input
        type={type}
        value={value}
        disabled={disabled}
        className={getFieldCSSClasses(touched, errors)}
        {...field}
        {...props}
        onChange={onChange}
        placeholder={`Enter ${label}`}
      />
      {withFeedbackLabel && (touched || errors) && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          // label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  shiftMappingForEdit: undefined,
  error: null,
  selectionForList: null,
  gradeList: null,
  selectionList: null,
  employeeTypeList: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const shiftMappingSlice = createSlice({
  name: "shiftMapping",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getrequisitonById
    shiftMappingFetched: (state, action) => {
      state.actionsLoading = false;
      state.shiftMappingForEdit = action.payload.shiftMappingForEdit;
      state.error = null;
    },

    selectionForList: (state, action) => {
      state.actionsLoading = false;
      state.selectionForList = action.payload.selectionForList;
      state.error = null;
    },
    selectionList: (state, action) => {
      state.actionsLoading = false;
      state.selectionList = action.payload.selectionList;
      state.error = null;
    },

    gradeList: (state, action) => {
      state.actionsLoading = false;
      state.gradeList = action.payload.gradeList;
      state.error = null;
    },

    employeeTypeList: (state, action) => {
      state.actionsLoading = false;
      state.employeeTypeList = action.payload.employeeTypeList;
      state.error = null;
    },

    // find requisiton
    allShiftMappingFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
  },
});

import { swalError } from "../../../helpers/swal";
import * as requestFromServer from "./notificationRequest";
import { callTypes, actions } from "./notificationSlice";

// const { actions } = notificationSlice;

export const getAllNotificationEventList = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getNotificationEventList(payload)
    .then((response) => {
      if (response.data.success) {
        const { data } = response.data;
        dispatch(
          actions.letterFetched({
            totalCount: data.totalItems,
            entities: data.hrms_notification_event,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
        }
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getAllEventActionList = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllEventActionList()
    .then((response) => {
      if (response.data.success) {
        const data = response.data?.data?.hrms_notification_event_actions?.map(
          (item, i) => {
            return {
              label: item?.hrms_notification_event_actions__name,
              value: item?.hrms_notification_event_actions__id,
            };
          }
        );
        dispatch(
          actions.eventListFetched({
            eventActionList: data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
        }
      }
    })
    .catch((error) => {
      // error.clientMessage = "Can't create buy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getOneEvent = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.eventOneFetched({
        eventForEdit: null,
      })
    );
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getEventOne(id)
    .then((response) => {
      if (response.data.success) {
        const { data } = response.data;
        dispatch(
          actions.eventOneFetched({
            eventForEdit: data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
        }
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getHEaderFooter = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.headerFooterFetched({
        headerFooter: null,
      })
    );
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllHeaderFooter(id)
    .then((response) => {
      if (response.data.success) {
        const { data } = response.data;
        dispatch(
          actions.headerFooterFetched({
            headerFooter: data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          //
        }
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

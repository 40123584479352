import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  requisitonForEdit: undefined,
  error: null,
  jobTitles: null,
  jobType: null,
  designation: null,
  departments: null,
  personByDept: null,
  personByDesignation: null,
  workstation: null,
  data: [],
  status: [],
  publicJobs: null,
  jobDetails: null,
  salaryRange: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const requisitonSlice = createSlice({
  name: "requisiton",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getrequisitonById
    requisitonFetched: (state, action) => {
      state.actionsLoading = false;
      state.requisitonForEdit = action.payload.requisitonForEdit;
      state.error = null;
    },

    jobTitlesFetched: (state, action) => {
      state.actionsLoading = false;
      state.jobTitles = action.payload.jobTitles;
      state.error = null;
    },

    jobTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.jobType = action.payload.jobType;
      state.error = null;
    },

    statusFetched: (state, action) => {
      state.actionsLoading = false;
      state.status = action.payload.status;
      state.error = null;
    },

    jobDetailsFetched: (state, action) => {
      state.actionsLoading = false;
      state.jobDetails = action.payload.jobDetails;
      state.error = null;
    },

    designationFetched: (state, action) => {
      state.actionsLoading = false;
      state.designation = action.payload.designation;
      state.error = null;
    },

    departmentFetched: (state, action) => {
      state.actionsLoading = false;
      state.departments = action.payload.departments;
      state.error = null;
    },

    salaryRangeFetch: (state, action) => {
      state.actionsLoading = false;
      state.salaryRange = action.payload.salaryRange;
      state.error = null;
    },

    personByDeptFetched: (state, action) => {
      state.actionsLoading = false;
      state.personByDept = action.payload.personByDept;
      state.error = null;
    },

    personByDesignationFetched: (state, action) => {
      state.actionsLoading = false;
      state.personByDesignation = action.payload.personByDesignation;
      state.error = null;
    },

    workstationFetched: (state, action) => {
      state.actionsLoading = false;
      state.workstation = action.payload.workstation;
      state.error = null;
    },

    publicJobsFetched: (state, action) => {
      state.actionsLoading = false;
      state.publicJobs = action.payload.publicJobs;
      state.error = null;
    },

    // find requisiton
    allRequisitonFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // update requisiton
    requisitonUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.buyForEdit = undefined;
    },

    // delete requisiton
    requisitonDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  leaveTypeForEdit: undefined,
  leavePolicyForEdit: undefined,
  leaveForEdit: undefined,
  error: null,
  leaveType: null,
  leavePolicy: null,
  leavePolicyDropdown: null,
  approvers: null,
  leaveSummery: null,
  employeeDetails: null,
  personByDept: null,
  employee: null,
  myleave: null,
  offDayMyleave: null,
  encashment: null,
  encashmentSummery: null,
  deductionList: null,
  deductionOne: null,
  docs: [],
  employeeCategory: null,
  workingHrs: [],
  hrms_leave_details: [],
  totalItems: 0,
  hrms_leave_encashments: [],
  encashment_employee: [],
  totalItemsLeaveEncashments: 0,
  hrms_leave_encashments_excel: [],
  hrms_leave_details_excel: []
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const leaveSlice = createSlice({
  name: "leave",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    leavetypeOneFetched: (state, action) => {
      state.actionsLoading = false;
      state.leaveTypeForEdit = action.payload.leaveTypeForEdit;
      state.error = null;
    },

    leavePolicyOneFetched: (state, action) => {
      state.actionsLoading = false;
      state.leavePolicyForEdit = action.payload.leavePolicyForEdit;
      state.error = null;
    },

    leaveOneFetched: (state, action) => {
      state.actionsLoading = false;
      state.leaveForEdit = action.payload.leaveForEdit;
      state.error = null;
    },

    leaveDeductionFetched: (state, action) => {
      state.actionsLoading = false;
      state.deductionList = action.payload.deductionList;
      state.error = null;
    },

    leaveDocFetched: (state, action) => {
      state.actionsLoading = false;
      state.docs = action.payload.docs;
      state.error = null;
    },

    leaveDeductionOneFetched: (state, action) => {
      state.actionsLoading = false;
      state.deductionOne = action.payload.deductionOne;
      state.error = null;
    },

    leaveTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.leaveType = action.payload.leaveType;
      state.error = null;
    },

    employeeListFetched: (state, action) => {
      state.actionsLoading = false;
      state.employee = action.payload.employee;
      state.error = null;
    },

    leaveSummeryFetched: (state, action) => {
      state.actionsLoading = false;
      state.leaveSummery = action.payload.leaveSummery;
      state.error = null;
    },

    encashmentSummeryFetched: (state, action) => {
      state.actionsLoading = false;
      state.encashmentSummery = action.payload.encashmentSummery;
      state.error = null;
    },

    getEmployeeOneFetched: (state, action) => {
      state.actionsLoading = false;
      state.employeeDetails = action.payload.employeeDetails;
      state.error = null;
    },

    leaveFetched: (state, action) => {
      state.actionsLoading = false;
      state.myleave = action.payload.myleave;
      state.error = null;
    },
    leaveReportFetched: (state, action) => {
      state.actionsLoading = false;
      state.hrms_leave_details = action.payload.hrms_leave_details;
      state.totalItems = action.payload.totalItems;
      state.error = null;
    },
    excelleaveReportFetched: (state, action) => {
      state.actionsLoading = false;
      state.hrms_leave_details_excel = action.payload.hrms_leave_details_excel;
      state.error = null;
    },
    leaveEncashmentReport: (state, action) => {
      state.actionsLoading = false;
      state.hrms_leave_encashments = action.payload.hrms_leave_encashments;
      state.totalItemsLeaveEncashments = action.payload.totalItemsLeaveEncashments;
      state.error = null;
    },
    getExcelLeaveEncashmentReport: (state, action) => {
      state.actionsLoading = false;
      state.hrms_leave_encashments_excel = action.payload.hrms_leave_encashments_excel;
      state.error = null;
    },

    OffDayLeaveFetched: (state, action) => {
      state.actionsLoading = false;
      state.offDayMyleave = action.payload.offDayMyleave;
      state.error = null;
    },

    encashmentFetched: (state, action) => {
      state.actionsLoading = false;
      state.encashment = action.payload.encashment;
      state.error = null;
    },

    personByDeptFetched: (state, action) => {
      state.actionsLoading = false;
      state.personByDept = action.payload.personByDept;
      state.error = null;
    },

    leavePolicyFetched: (state, action) => {
      state.actionsLoading = false;
      state.leavePolicy = action.payload.leavePolicy;
      state.error = null;
    },

    leavePolicyDropdownFetched: (state, action) => {
      state.actionsLoading = false;
      state.leavePolicyDropdown = action.payload.leavePolicyDropdown;
      state.error = null;
    },

    approversFetched: (state, action) => {
      state.actionsLoading = false;
      state.approvers = action.payload.approvers;
      state.error = null;
    },
    EncashmentEmployee: (state, action) => {
      state.actionsLoading = false;
      state.encashment_employee = action.payload.encashment_employee;
      state.error = null;
    },
    employeeCategoryFetched: (state, action) => {
      state.actionsLoading = false;
      state.employeeCategory = action.payload.employeeCategory;
      state.error = null;
    },
    workingHrsFetched: (state, action) => {
      state.actionsLoading = false;
      state.workingHrs = action.payload.workingHrs;
      state.error = null;
    },
  },
});

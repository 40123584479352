import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  jobDescriptionForEdit: undefined,
  lastError: null,
  error: null
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const perfomSlice = createSlice({
  name: "perfomance",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    EmployeeCategoriesFetched: (state, action) => {
      state.actionsLoading = false;
      state.employee_cat = action.payload.employee_cat;
      state.error = null;
    }, 

    ApaCategoriesFetched: (state, action) => {
      state.actionsLoading = false;
      state.apa_category = action.payload.apa_category;
      state.error = null;
    }, 

    ApaApprisarGroupsFetched: (state, action) => {
      state.actionsLoading = false;
      state.apa_apprisar_group = action.payload.apa_apprisar_group;
      state.error = null;
    }, 

    ApaApprisarGroupsMasterFetched: (state, action) => {
      state.actionsLoading = false;
      state.apa_apprisar_group_master = action.payload.apa_apprisar_group_master;
      state.error = null;
    }, 

    KpiQuestionsListFetched: (state, action) => {
      state.actionsLoading = false;
      state.kpi_questions = action.payload.kpi_questions;
      state.error = null;
    }, 

    ApaCategoryListFetched: (state, action) => {
      state.actionsLoading = false;
      state.apa_category_list = action.payload.apa_category_list;
      state.error = null;
    }, 

    ApaMarksListFetched: (state, action) => {
      state.actionsLoading = false;
      state.apa_marks = action.payload.apa_marks;
      state.error = null;
    }, 

    ApaTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.hrms_apa_types = action.payload.hrms_apa_types;
      state.error = null;
    }, 
    
    EmployeeListFetched: (state, action) => {
      state.actionsLoading = false;
      state.employeeList = action.payload.employeeList;
      state.error = null;
    }, 

    EmployeeFeedbackQuestionFetched: (state, action) => {
      state.actionsLoading = false;
      state.employeeFeedbackQuestion = action.payload.employeeFeedbackQuestion;
      state.error = null;
    }, 
    
    KpiQuestionByEmployeeFetched: (state, action) => {
      state.actionsLoading = false;
      state.kpiQuestionByEmployee = action.payload.kpiQuestionByEmployee;
      state.error = null;
    }, 
   
    GuestionFormNameFetched: (state, action) => {
      state.actionsLoading = false;
      state.questionFormNameList = action.payload.questionFormNameList;
      state.error = null;
    }, 

    ApaFormListDataFetched: (state, action) => {
      state.actionsLoading = false;
      state.apa_form_data = action.payload.apa_form_data;
      state.error = null;
    }, 

    AppraisalsListFetched: (state, action) => {
      state.actionsLoading = false;
      state.appraisals_list = action.payload.appraisals_list;
      state.error = null;
    }, 
   
    ApprisationForFetched: (state, action) => {
      state.actionsLoading = false;
      state.apa_apprisation_for = action.payload.apa_apprisation_for;
      state.error = null;
    }, 
    
    AssesorCategoryWeightFetched: (state, action) => {
      state.actionsLoading = false;
      state.assesor_weight_list = action.payload.assesor_weight_list;
      state.error = null;
    },

    AssesorSubCategoryWeightFetched: (state, action) => {
      state.actionsLoading = false;
      state.sub_assesor_weight_list = action.payload.sub_assesor_weight_list;
      state.error = null;
    }, 

    AssessorMasterDataFetched: (state, action) => {
      state.actionsLoading = false;
      state.assessor_master_data = action.payload.assessor_master_data;
      state.error = null;
    }, 
     
  },
});

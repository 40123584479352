import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  jobDescriptionForEdit: undefined,
  error: null,
  dropdown: null,
  applicantSorted: null,
  interviews: null,
  questions: null,
  approvedList: null,
  educationLavel: null,
  degree: null,
  resultType: null,
  majorSubject: null,
  board: null,
  institute: null,
  data: [],
  educationList: [],
  certificationList: [],
  experienceList: [],
  division: [],
  pre_district: [],
  per_district: [],
  pre_thana: [],
  per_thana: [],
  personal_info: null,
  certificate: [],
  industryType: [],
  trainingList: [],
  countryList: [],
  referenceList: [],
  languageList: [],
  publicationList: [],
  portfolioList: [],
  documentType: [],
  documentList: [],
  bankList: [],
  banks: [],
  employeeList: [],
  nationalityList: [],
  branchList: [],
  assessmentList: [],
  jobList: [],
  publicationsOrganizations: [],
  mejorSubjects: [],
  educationLevel: [],
  additionInfo: null,
  interviewJob: [],
  cv_view: null,
  educationAllList: [],
  scheduleForEdit: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const applicantSlice = createSlice({
  name: "applicant",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    jobDescriptionFetched: (state, action) => {
      state.actionsLoading = false;
      state.jobDescriptionForEdit = action.payload.jobDescriptionForEdit;
      state.error = null;
    },

    allSortedApplicantFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    allPublicationsOrganizations: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.publicationsOrganizations = entities;
    },
    allEducationLevels: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.educationLevel = entities;
    },
    allMejorSubjects: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.mejorSubjects = entities;
    },
    allInterviewFetched: (state, action) => {
      const { totalCount, interviews } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.interviews = interviews;
      state.totalCount = totalCount;
    },

    allApprovedInterviewFetched: (state, action) => {
      const { totalCount, interviews } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.approvedList = interviews;
      state.totalCount = totalCount;
    },

    applicantDeleted: (state, action) => {
      console.log(action.payload);
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.hrms_job_application__applicant_id !== action.payload.id
      );
    },

    jobDescriptionQuestions: (state, action) => {
      state.actionsLoading = false;
      state.questions = action.payload.questions;
      state.error = null;
    },

    applicantDetails: (state, action) => {
      state.actionsLoading = false;
      state.cv_view = action.payload.cv_view;
      state.error = null;
    },

    assessmentListFetched: (state, action) => {
      state.actionsLoading = false;
      state.assessmentList = action.payload.assessmentList;
      state.error = null;
    },

    educationLavelFetched: (state, action) => {
      state.actionsLoading = false;
      state.educationLavel = action.payload.educationLavel;
      state.error = null;
    },

    employeeListFetched: (state, action) => {
      state.actionsLoading = false;
      state.employeeList = action.payload.employeeList;
      state.error = null;
    },

    jobListFetched: (state, action) => {
      state.actionsLoading = false;
      state.jobList = action.payload.jobList;
      state.error = null;
    },

    jobInterviewListFetched: (state, action) => {
      state.actionsLoading = false;
      state.interviewJob = action.payload.interviewJob;
      state.error = null;
    },

    scheduleInfoFetched: (state, action) => {
      state.actionsLoading = false;
      state.scheduleForEdit = action.payload.scheduleForEdit;
      state.error = null;
    },

    degreeFetched: (state, action) => {
      state.actionsLoading = false;
      state.degree = action.payload.degree;
      state.error = null;
    },

    resultTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.resultType = action.payload.resultType;
      state.error = null;
    },

    boardFetched: (state, action) => {
      state.actionsLoading = false;
      state.board = action.payload.board;
      state.error = null;
    },

    instituteFetched: (state, action) => {
      state.actionsLoading = false;
      state.institute = action.payload.institute;
      state.error = null;
    },

    majorSubjectFetched: (state, action) => {
      state.actionsLoading = false;
      state.majorSubject = action.payload.majorSubject;
      state.error = null;
    },

    // find requisiton
    allRecruitementFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // update requisiton
    educationListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.educationList = action.payload.educationList;
    },

    educationListAllFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.educationAllList = action.payload.educationList;
    },

    divisionListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.division = action.payload.division;
    },

    nationalityListFetched: (state, action) => {
      state.error = null;
      state.nationalityList = action.payload.nationalityList;
      state.actionsLoading = false;
    },

    preDistrictListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.pre_district = action.payload.pre_district;
    },

    perDistrictListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.per_district = action.payload.per_district;
    },

    preThanaListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.pre_thana = action.payload.pre_thana;
    },

    perThanaListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.per_thana = action.payload.per_thana;
    },
    personalInfoFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.personal_info = action.payload.personal_info;
    },
    certificationFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.certificate = action.payload.certificate;
    },
    certificationListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.certificationList = action.payload.certificationList;
    },
    documentListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.documentList = action.payload.documentList;
    },
    documentTypeFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.documentType = action.payload.documentType;
    },
    industryTypeFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.industryType = action.payload.industryType;
    },
    experienceFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.experienceList = action.payload.experienceList;
    },
    trainingFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.trainingList = action.payload.trainingList;
    },
    countryListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.countryList = action.payload.countryList;
    },
    additionalFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.additionInfo = action.payload.additionInfo;
    },
    referenceListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.referenceList = action.payload.referenceList;
    },
    publicationListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.publicationList = action.payload.publicationList;
    },
    languageListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.languageList = action.payload.languageList;
    },
    portfolioListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.portfolioList = action.payload.portfolioList;
    },
    bankListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.bankList = action.payload.bankList;
    },
    branchListFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.branchList = action.payload.branchList;
    },
    banksFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.banks = action.payload.banks;
    },
    referenceFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.referenceList = action.payload.referenceList;
    },
    disciplinaryRecordFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.disciplinaryRecordList = action.payload.disciplinaryRecordList;
    },
    workStationFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.workStationList = action.payload.workStationList;
    },
    frcFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.frcList = action.payload.frcList;
    },
  },
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  assignTemplateForEdit: undefined,
  error: null,
  assignTemplateDropdown: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const assignTemplateSlice = createSlice({
  name: "assignTemplate",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    allAssignTemplateFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // getrequisitonById
    assignTemplateFetched: (state, action) => {
      state.actionsLoading = false;
      state.assignTemplateForEdit = action.payload.assignTemplateForEdit;
      state.error = null;
    },

    assignTemplateDropdown: (state, action) => {
      state.actionsLoading = false;
      state.assignTemplateDropdown = action.payload.assignTemplateDropdown;
      state.error = null;
    },
  },
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  monthly: null,
  lastError: null,
  error: null,
  attendanceForEdit: null,
  movementForEdit: null,
  attendanceSettings: null,
  attendanceHistory: [],
  entities: [],
  leaveType: [],
  movement: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getrequisitonById
    attendanceFetched: (state, action) => {
      state.actionsLoading = false;
      state.attendanceForEdit = action.payload.attendanceForEdit;
      state.error = null;
    },

    // getrequisitonById
    movementFetched: (state, action) => {
      state.actionsLoading = false;
      state.movementForEdit = action.payload.movementForEdit;
      state.error = null;
    },

    attendanceHistoryFetched: (state, action) => {
      state.actionsLoading = false;
      state.attendanceHistory = action.payload.attendanceHistory;
      state.error = null;
    },

    attendanceMovementFetched: (state, action) => {
      state.actionsLoading = false;
      state.movement = action.payload.movement;
      state.error = null;
    },

    attendanceSettingsFetched: (state, action) => {
      state.actionsLoading = false;
      state.attendanceSettings = action.payload.attendanceSettings;
      state.error = null;
    },

    leaveTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.leaveType = action.payload.leaveType;
      state.error = null;
    },

    attendanceTypeListFetched: (state, action) => {
      state.actionsLoading = false;
      state.attendanceTypeList = action.payload.attendanceTypeList;
      state.error = null;
    },

    attendanceTargetGroupFetched: (state, action) => {
      state.actionsLoading = false;
      state.attendanceTargetGroups = action.payload.attendanceTargetGroups;
      state.error = null;
    },

    attendanceTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.attendanceType = action.payload.attendanceType;
      state.error = null;
    },

    allAttendanceFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    allAttendanceFetchedMonthly: (state, action) => {
      const { entities, totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.monthly = entities;
      state.totalCount = totalCount;
    },

    attendanceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.attendanceForEdit = undefined;
    },

    attendanceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});

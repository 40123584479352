import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  weekendForEdit: undefined,
  error: null,
  weekendTargetGroups: null,
  weekendType: null,
  selectedWeekendTargetGroups: [],
  start_date: null,
  end_date: null,
  applyEveryYear: null,
  weekendRemark: null,
  totalWeekends: 0,
  data: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const weekendSlice = createSlice({
  name: "weekend",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    weekendFetched: (state, action) => {
      state.actionsLoading = false;
      state.weekendForEdit = action.payload.weekendForEdit;
      state.error = null;
    },

    weekendTargetGroupFetched: (state, action) => {
      state.actionsLoading = false;
      state.weekendTargetGroups = action.payload.weekendTargetGroups;
      state.error = null;
    },

    weekendTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.weekendType = action.payload.weekendType;
      state.error = null;
    },

    allWeekendsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    weekendUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.weekendForEdit = undefined;
    },

    weekendDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import React, { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    getFeturePermission,
    getMenuByModule,
    getModuleList,
} from "../../../../../app/modules/Auth/redux/authCrud";
import { actions } from "../../../../../app/modules/Auth/redux/authRedux";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { SiLitiengine } from "react-icons/si";
import jwtDecode from "jwt-decode";
import * as LetterAction from "../../../../../app/pages/letter-management/_redux/letterManagementActions";

export function LanguageSelectorDropdown(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { modules, user } = useSelector((state) => state.auth);
    const { authToken } = useSelector(
        ({ auth }) => ({
            authToken: auth.coreModuleInfo,
        }),
        shallowEqual
    );

    const menuType =
        localStorage.getItem("menuType") &&
        JSON.parse(localStorage.getItem("menuType"));

    const getModuleLists = async () => {
        await getModuleList().then(async (res) => {
            if (!res.error) {
                dispatch(actions.modules(res?.data));

                const findOne = res?.data?.find((item) => item.module_name == "HRMS");

                if (findOne && Object.keys(findOne).length > 0) {
                    const data = {
                        type: findOne.module_name,
                        id: findOne.module_id,
                        slug: findOne.module_url,
                    };
                    await getPermission();
                    await getMenu(data);
                    localStorage.setItem("menuType", JSON.stringify(data));
                    dispatch(actions.menuType(data));
                }
            }
        });
    };

    const getMenu = async (menuType) => {
        if (menuType?.id) {
            await getMenuByModule(menuType.id, 1).then((res) => {
                if (!res.error) {
                    dispatch(actions.menu(res?.data));
                }
            });
        }
    };

    const getPermission = async () => {
        try {
            const permissions = (await getFeturePermission()).data?.map((d) => d.name);
            dispatch(actions.setFEPermission(permissions));
        } catch (e) {
            console.log(e);
        }
    };

    const handleModule = async (type, id, slug) => {
        const data = {
            type: type,
            id: id,
            slug: slug,
        };
        // localStorage.setItem("menuType", JSON.stringify(data));
        // dispatch(actions.menuType(data));
        // if (data.type != "HRMS") {
        //   window.location.href = `${process.env.REACT_APP_ERP_FE}/?moduleId=${data.id}`;
        // }
        // await getMenu(data);
        // return history.push(`/dashboard/${slug}`);
    };

    useEffect(() => {
        if (authToken && Object.keys(authToken).length > 0) {
            getModuleLists();
        }
    }, [authToken]);

    useEffect(() => {
        let menuType =
            localStorage.getItem("menuType") &&
            JSON.parse(localStorage.getItem("menuType"));

        // if (defaultModule && Object.keys(defaultModule).length > 0) {
        // }

        if (menuType) {
            dispatch(actions.menuType(menuType));
        }
    }, []);

    useEffect(() => {
        if (authToken && Object.keys(authToken).length > 0) {
            dispatch(LetterAction.getAllLetterVariables());
            dispatch(LetterAction.getAllApplicantVariables());
        }
    }, [dispatch, authToken]);

    return (
        <Dropdown drop="down" alignRight>
            {/* <Dropdown.Toggle
                as={DropdownTopbarItemToggler}
                id="dropdown-toggle-my-cart"
            >
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="language-panel-tooltip">
                            {menuType?.type ? menuType?.type : "Select Module"}
                        </Tooltip>
                    }
                >
                    <div
                        className="btn btn-dropdown btn-lg mr-1 topbar-helper"
                        style={{
                            color: "rgba(0, 0, 0, 0.65)",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            fontWeight: "400",
                        }}
                    >
                        <SiLitiengine className="topbar-helper" />{" "}
                        {menuType?.type ?? "Select Module"} <MdOutlineKeyboardArrowDown />
                    </div>
                </OverlayTrigger>
            </Dropdown.Toggle> */}
            <Dropdown.Menu
                className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"
                style={{ maxHeight: "90vh", overflowY: "auto", width: "202px" }}
            >
                <ul className="navi navi-hover py-0">
                    {modules &&
                        modules.length > 0 &&
                        modules.map((item, i) => (
                            <li
                                className={clsx("navi-item", {
                                    active: false,
                                })}
                                key={i}
                                onClick={() =>
                                    handleModule(
                                        item.module_name,
                                        item.module_id,
                                        item.module_url
                                    )
                                }
                            >
                                <a className="nav-link">
                                    <span className="navi-text">{item.module_name}</span>
                                </a>
                            </li>
                        ))}
                </ul>
            </Dropdown.Menu>
        </Dropdown>
    );
}

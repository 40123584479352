import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  letterTypeForEdit: undefined,
  letterPolicyForEdit: undefined,
  letterForEdit: undefined,
  error: null,
  letterVariables: null,
  letterTypes: null,
  headerFooter: null,
  eventActionList: null,
  eventForEdit: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    letterVariablesFetched: (state, action) => {
      state.actionsLoading = false;
      state.letterVariables = action.payload.letterVariables;
      state.error = null;
    },

    letterFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    letterTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.letterTypes = action.payload.letterTypes;
      state.error = null;
    },

    headerFooterFetched: (state, action) => {
      state.actionsLoading = false;
      state.headerFooter = action.payload.headerFooter;
      state.error = null;
    },

    eventListFetched: (state, action) => {
      state.actionsLoading = false;
      state.eventActionList = action.payload.eventActionList;
      state.error = null;
    },

    eventOneFetched: (state, action) => {
      state.actionsLoading = false;
      state.eventForEdit = action.payload.eventForEdit;
      state.error = null;
    },

    letterOneFetched: (state, action) => {
      state.actionsLoading = false;
      state.letterForEdit = action.payload.letterForEdit;
      state.error = null;
    },
  },
});

const persistConfig = {
  key: "notification",
  storage,
  whitelist: ["headerFooter"], // Add the keys you want to persist
};

export const persistedNotificationReducer = persistReducer(
  persistConfig,
  notificationSlice.reducer
);
export const { actions } = notificationSlice;

import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  letterTypeForEdit: undefined,
  letterPolicyForEdit: undefined,
  letterForEdit: undefined,
  error: null,
  letterVariables: null,
  applicantVariables: null,
  letterTypes: null,
  countries: null,
  requestedLetter: null,
  letterTemplate: null,
  letterTemplateDropdown: null,
  headerFooter: null,
  eventList: null,
  eventForEdit: null,
  letterForUser: null,
  letterDropdown: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

const letterSlice = createSlice({
  name: "letter",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    letterVariablesFetched: (state, action) => {
      state.actionsLoading = false;
      state.letterVariables = action.payload.letterVariables;
      state.error = null;
    },

    applicantVariablesFetched: (state, action) => {
      state.actionsLoading = false;
      state.applicantVariables = action.payload.applicantVariables;
      state.error = null;
    },

    letterFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    letterDropdownFetched: (state, action) => {
      const { letterDropdown } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.letterDropdown = letterDropdown;
    },

    letterTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.letterTypes = action.payload.letterTypes;
      state.error = null;
    },

    requestedLetterFetched: (state, action) => {
      state.actionsLoading = false;
      state.requestedLetter = action.payload.requestedLetter;
      state.error = null;
    },

    countryFetched: (state, action) => {
      state.actionsLoading = false;
      state.countries = action.payload.countries;
      state.error = null;
    },

    letterTemplateFetched: (state, action) => {
      state.actionsLoading = false;
      state.letterTemplate = action.payload.letterTemplate;
      state.letterTemplateDropdown = action.payload.letterTemplateDropdown;
      state.error = null;
    },

    headerFooterFetched: (state, action) => {
      state.actionsLoading = false;
      state.headerFooter = action.payload.headerFooter;
      state.error = null;
    },

    latterForUserFetched: (state, action) => {
      state.actionsLoading = false;
      state.letterForUser = action.payload.letterForUser;
      state.error = null;
    },

    eventListFetched: (state, action) => {
      state.actionsLoading = false;
      state.eventList = action.payload.eventList;
      state.error = null;
    },

    eventOneFetched: (state, action) => {
      state.actionsLoading = false;
      state.eventForEdit = action.payload.eventForEdit;
      state.error = null;
    },

    letterOneFetched: (state, action) => {
      state.actionsLoading = false;
      state.letterForEdit = action.payload.letterForEdit;
      state.error = null;
    },
  },
});

const persistConfig = {
  key: "letter",
  storage,
  whitelist: ["letterVariables", "applicantVariables"], // Add the keys you want to persist
};

export const persistedLetterReducer = persistReducer(
  persistConfig,
  letterSlice.reducer
);
export const { actions } = letterSlice;

/* eslint-disable no-useless-computed-key */
import { createSlice } from "@reduxjs/toolkit";

const initState = {
  listLoading: false,
  actionsLoading: false,
  lastError: null,
  shiftForEdit: null,
  columns: {},
  employee: [],
  shiftList: [],
  dropdown: [],
  holidayList: [],
  shiftDropdown: [],
  totalCount: 0,
  entities: null,
  employeeCategory: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const shiftSlice = createSlice({
  name: "shift",
  initialState: initState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // add to list
    addTodo: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.columns = action.payload;
      localStorage.setItem("TODO", JSON.stringify(action.payload));
    },

    employeeListFetched: (state, action) => {
      state.actionsLoading = false;
      state.employee = action.payload.employee;
      state.error = null;
    },

    shiftListFetched: (state, action) => {
      state.actionsLoading = false;
      state.shiftList = action.payload.shiftList;
      state.error = null;
    },

    holidayListFetched: (state, action) => {
      state.actionsLoading = false;
      state.holidayList = action.payload.holidayList;
      state.error = null;
    },

    shiftFetched: (state, action) => {
      state.actionsLoading = false;
      state.shiftForEdit = action.payload.shiftForEdit;
      state.error = null;
    },

    employeeCategoryFetched: (state, action) => {
      state.actionsLoading = false;
      state.employeeCategory = action.payload.employeeCategory;
      state.error = null;
    },

    shiftDropdownFetched: (state, action) => {
      state.actionsLoading = false;
      state.shiftDropdown = action.payload.shiftDropdown;
      state.error = null;
    },

    allShiftFetched: (state, action) => {
      const { totalCount, entities, dropdown } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.dropdown = dropdown;
    },
  },
});

import { swalError, swalSuccess } from "../../../helpers/swal";
import * as requestFromServer from "./letterManagementRequest";
import { callTypes, actions } from "./letterManagementSlice";

export const getAllLetterList = (template_type_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLetterList(template_type_id)
    .then((response) => {
      if (response.data.success) {
        const { data } = response.data;
        dispatch(
          actions.letterFetched({
            totalCount: data.totalItems,
            entities: data.hrms_letter_template,
          })
        );
        const res = data.hrms_letter_template?.map((item) => {
          return {
            label: item?.template_name,
            value: item?.id,
          };
        });
        dispatch(
          actions.letterDropdownFetched({
            letterDropdown: res,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          swalError("something went wrong");
        }
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getAllLetterVariables = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllTemplateVariable()
    .then((response) => {
      if (response.data.success) {
        const data = response.data?.data?.map((item, i) => {
          return {
            label: item,
            value: item,
          };
        });
        dispatch(
          actions.letterVariablesFetched({
            letterVariables: data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          swalError("something went wrong");
        }
      }
    })
    .catch((error) => {
      // error.clientMessage = "Can't create buy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getAllApplicantVariables = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllApplicantVariable()
    .then((response) => {
      if (response.data.success) {
        const data = response.data?.data?.map((item, i) => {
          return {
            label: item,
            value: item,
          };
        });
        dispatch(
          actions.applicantVariablesFetched({
            applicantVariables: data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          swalError("something went wrong");
        }
      }
    })
    .catch((error) => {
      // error.clientMessage = "Can't create buy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getAllEventList = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllEventList()
    .then((response) => {
      if (response.data.success) {
        const data = response.data?.data?.hrms_notification_event?.map(
          (item, i) => {
            return {
              label: item?.event_name,
              value: item?.id,
            };
          }
        );
        dispatch(
          actions.eventListFetched({
            eventList: data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          swalError("something went wrong");
        }
      }
    })
    .catch((error) => {
      // error.clientMessage = "Can't create buy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getAllLetterTypes = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllTemplateType()
    .then((response) => {
      if (response.data.success) {
        const data = response.data?.data?.hrms_letter_template_type?.map(
          (item) => {
            return {
              label: item.hrms_letter_template_type__name,
              value: item.hrms_letter_template_type__id,
            };
          }
        );

        dispatch(
          actions.letterTypeFetched({
            letterTypes: data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          swalError("something went wrong");
        }
      }
    })
    .catch((error) => {
      // error.clientMessage = "Can't create buy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getAllLetterTemplate = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllTemplate()
    .then((response) => {
      if (response.data.success) {
        const data = response.data?.data?.map((item) => {
          return {
            label: item.template_name,
            value: item.id,
          };
        });

        dispatch(
          actions.letterTemplateFetched({
            letterTemplateDropdown: data,
            letterTemplate: response.data?.data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          // swalError("something went wrong");
        }
      }
    })
    .catch((error) => {
      // error.clientMessage = "Can't create buy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getAllCountries = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllCountries()
    .then((response) => {
      if (response.data.success) {
        const data = response.data?.data?.config_countries?.map((item) => {
          return {
            label: item.config_countries__country_name,
            value: item.config_countries__country_name,
          };
        });

        dispatch(
          actions.countryFetched({
            countries: data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          // swalError("something went wrong");
        }
      }
    })
    .catch((error) => {
      // error.clientMessage = "Can't create buy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getAllLetterHeaderFooter = (company_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllHeaderFooter(company_id)
    .then((response) => {
      if (response.data.success) {
        dispatch(
          actions.headerFooterFetched({
            headerFooter: response.data?.data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          // swalError("something went wrong");
        }
      }
    })
    .catch((error) => {
      // error.clientMessage = "Can't create buy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getLetterForUser = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLetterByUserId(payload)
    .then((response) => {
      if (response.data.success) {
        dispatch(
          actions.latterForUserFetched({
            letterForUser: response.data?.data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          swalError("something went wrong");
        }
      }
    })
    .catch((error) => {
      // error.clientMessage = "Can't create buy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getRequestedLetter = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRequestedLetterList(payload)
    .then((response) => {
      if (response.data.success) {
        dispatch(
          actions.requestedLetterFetched({
            requestedLetter: response.data?.data?.hrms_letter_requests,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          swalError("something went wrong");
        }
      }
    })
    .catch((error) => {
      // error.clientMessage = "Can't create buy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getOneEvent = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.eventOneFetched({
        eventForEdit: null,
      })
    );
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getEventOne(id)
    .then((response) => {
      if (response.data.success) {
        const { data } = response.data;
        dispatch(
          actions.eventOneFetched({
            eventForEdit: data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          swalError("something went wrong");
        }
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getOneLetter = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.letterOneFetched({
        letterForEdit: null,
      })
    );
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLetterOne(id)
    .then((response) => {
      if (response.data.success) {
        const { data } = response.data;
        dispatch(
          actions.letterOneFetched({
            letterForEdit: data,
          })
        );
      } else {
        if (response.data.message) {
          swalError(response.data.message);
        } else {
          swalError("something went wrong");
        }
      }
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

import React from "react";
import { Dashboard } from "../../_metronic/_partials";
import { Demo1Dashboard } from "../../_metronic/_partials/dashboards/Demo1Dashboard";

export function DashboardPage() {
    return (
        <>
            {/* <Dashboard />
            <Demo1Dashboard /> */}

            <div className="dashboard_main_wrapper">
                {/* <div>
                    <h2 className="dash_title">Welcome to our  <br/><span> HRMS</span></h2>
                    <img className="img-fluid dash_icon" src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/illustrations/easy/1.svg" alt="cover" />
                </div> */}
            </div>

        </>
    )
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    actionsLoading: false,
    listLoading: false,
    listData: [],
    totalData: 0,
    designations: [],
    categories: [],
    types: [],
    costcenters: [],
    salaryComponents: [],
    chartofacc: [],
    templates: [],

    disbursementData: null,
    disbursementRecords: [],
    salaryDetails: null,
    slabData: null,
    salaryComponentsDropdown: [],
    histories: [],
    totalItems: null,
    delegationList: [],
    totalDelegationItems: null,
    employees: [],
    promotionRequests: [],
    totalPromotionRequestItems: null,
};
export const callTypes = {
    list: "list",
    action: "action",
};
export const wageSlice = createSlice({
    name: "wagemanager",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        setListData: (state, action) => {
            state.error = null;
            state.listData = action.payload.listData;
            state.totalData = action.payload.totalData;
            state.listLoading = false;

        },
        setEmployeeTypeNDesignationNCategory: (state, action) => {
            state.designations = action.payload.designations;
            state.categories = action.payload.categories;
            state.types = action.payload.types;
        },
        setCostCenters: (state, action) => {
            state.costcenters = action.payload;
        },
        setChartOfAcc: (state, action) => {
            state.chartofacc = action.payload;
        },
        setSalaryComponents: (state, action) => {
            state.salaryComponents = action.payload;
        },
        setDisbursementData: (state, action) => {
            console.log(action.payload)
            state.disbursementData = action.payload;
        },
        setDisbursementRecords: (state, action) => {
            console.log(action.payload)
            state.disbursementRecords = action.payload;
        },
        setEmployeeSalaryDetails: (state, action) => {
            state.error = null;
            state.salaryDetails = action.payload.salaryDetails;
        },
        setEligableTemplates: (state, action) => {
            state.error = null;
            state.templates = action.payload.templates;
        },
        setSlab: (state, action) => {
            state.error = null;
            state.slabData = action.payload.slabData;
        },
        setSalaryComponentsDropdown: (state, action) => {
            state.error = null;
            state.salaryComponentsDropdown = action.payload.salaryComponentsDropdown;
        },
        setAllUnits: (state, action) => {
            state.error = null;
            state.unitsDropDown = action.payload.unitsDropDown;
        },
        setHistory: (state, action) => {
            state.error = null;
            state.totalItems = action.payload.totalItems;
            state.histories = action.payload.histories;
        },
        setPromotionRequest: (state, action) => {
            state.error = null;
            state.totalPromotionRequestItems = action.payload.totalPromotionRequestItems;
            state.promotionRequests = action.payload.promotionRequests;
        },
        setDelegationListData: (state, action) => {
            state.error = null;
            state.totalDelegationItems = action.payload.totalDelegationItems;
            state.delegationList = action.payload.delegationList;
        },
        employeesFetched: (state, action) => {
            state.error = null;
            state.employees = action.payload.employees
        }
    }
});
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  slabEntities: null,
  lastError: null,
  rebateSettingsForEdit: undefined,
  error: null,
  weekendTargetGroups: null,
  weekendType: null,
  selectedWeekendTargetGroups: [],
  start_date: null,
  end_date: null,
  applyEveryYear: null,
  weekendRemark: null,
  totalWeekends: 0,
  taxCategory: [],
  tax_circle: [],
  tax_chalan: [],
  entities: [],
  tax_circle_dropdown: [],
  cc_list: [],
  cc_dropdown: [],
  taxEmp: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const taxSlice = createSlice({
  name: "tax",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    rebateSettingsFetched: (state, action) => {
      state.actionsLoading = false;
      state.rebateSettingsForEdit = action.payload.rebateSettingsForEdit;
      state.error = null;
    },

    ccFetched: (state, action) => {
      state.actionsLoading = false;
      state.cc_dropdown = action.payload.cc_dropdown;
      state.cc_list = action.payload.cc_list;
      state.error = null;
    },

    taxEmpFetched: (state, action) => {
      state.actionsLoading = false;
      state.taxEmp = action.payload.taxEmp;
      state.error = null;
    },

    taxCategoryFetched: (state, action) => {
      state.actionsLoading = false;
      state.taxCategory = action.payload.taxCategory;
      state.error = null;
    },

    taxChalanFetched: (state, action) => {
      state.actionsLoading = false;
      state.tax_chalan = action.payload.tax_chalan;
      state.error = null;
    },

    taxCircleFetched: (state, action) => {
      state.actionsLoading = false;
      state.tax_circle = action.payload.tax_circle;
      state.tax_circle_dropdown = action.payload.tax_circle_dropdown;
      state.error = null;
    },

    allWeekendsFetched: (state, action) => {
      const { totalCount, slabEntities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.slabEntities = slabEntities;
      state.totalCount = totalCount;
    },

    allTaxHistoryFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    taxHeadFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.taxHead = action.payload.taxHead;
    },

    weekendDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.slabEntities = state.slabEntities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  error: null,
  singlePolicy:null
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const policySlice = createSlice({
  name: "policy",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    allPolicyFetched: (state, action) => {
      state.actionsLoading = false;
      state.entities = action.payload.entities;
      state.error = null;
    },

    policyFetched:(state,action)=>{
      state.actionsLoading = false;
      state.singlePolicy = action.payload.singlePolicy;
      state.error = null;
    },

    allDocumentPolicyFetched: (state, action) => {
      state.actionsLoading = false;
      state.policyList = action.payload.policyList;
      state.error = null;
    },

    allEventDataFetched: (state, action) => {
      state.actionsLoading = false;
      state.eventList = action.payload.eventList;
      state.error = null;
    },

    


    policyDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.hrms_event_policy__id !== action.payload.id
      );
    },
  },
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    lastError: null,
    employeeList: null,
    supervisorList: null,
    CategoriesList: null,
    error: null
};

export const callTypes = {
    list: "list",
    action: "action",
};

export const employeeSlice = createSlice({
    name: "employee",
    initialState: initialState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },

        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        allEmployeeFetched: (state, action) => {
            state.actionsLoading = false;
            state.entities = action.payload.entities;
            state.error = null;
        },
        employeeProfileFetched: (state, action) => {
            state.actionsLoading = false;
            state.employeeProfile = action.payload.employeeProfile;
            state.error = null;
        },

        supervisorListFetched: (state, action) => {
            state.actionsLoading = false;
            state.supervisorList = action.payload.supervisorList;
            state.error = null;
        },

        employeeDocumentListFetched: (state, action) => {
            state.actionsLoading = false;
            state.employeeDocumentList = action.payload.employeeDocumentList;
            state.error = null;
        },

        documentListFetched: (state, action) => {
            state.actionsLoading = false;
            state.documentList = action.payload.documentList;
            state.error = null;
        },

        employeeDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                (el) => el.hrms_employee__id !== action.payload.id
            );
        },

        allCategoriesListFetched: (state, action) => {
            state.actionsLoading = false;
            state.employeeCategoriesList = action.payload.employeeCategoriesList;
            state.error = null;
        },

        allGradeListFetched: (state, action) => {
            state.actionsLoading = false;
            state.gradeList = action.payload.gradeList;
            state.error = null;
        },
        allTrainingFetched: (state, action) => {
            state.actionsLoading = false;
            state.trainingList = action.payload.trainingList;
            state.error = null;
        },
        allCertificationsFetched: (state, action) => {
            state.actionsLoading = false;
            state.certificationList = action.payload.certificationList;
            state.error = null;
        },
        educationListEmpFetched: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.educationListEmp = action.payload.educationListEmp;
        },
        documentCheckListFetched: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.documentCheckList = action.payload.documentCheckList;
        },
        documentCheckListCatFetched: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.documentCheckListCat = action.payload.documentCheckListCat;
        },
        empTimeLineFetched: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.empTimeLine = action.payload.empTimeLine;
        },
    },
});

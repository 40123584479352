import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

import * as auth from "../app/modules/Auth/redux/authRedux";
import { applicantSlice } from "../app/pages/applicant/_redux/applicantSlice";
import { attendanceSlice } from "../app/pages/attendance/_redux/attendanceSlice";
import { delegationSlice } from "../app/pages/delegation/_redux/delegationSlice";
import { employeeSlice } from "../app/pages/employee/_redux/employeeSlice";
import { holidaySetupSlice } from "../app/pages/holidays/_redux/holidaySetupSlice";
import { jobDescriptionSlice } from "../app/pages/job-description/_redux/jobDescriptionSlice";
import { leaveSlice } from "../app/pages/leave-management/_redux/leaveSlice";
import { persistedLetterReducer } from "../app/pages/letter-management/_redux/letterManagementSlice";
import { loanSlice } from "../app/pages/loan/_redux/loanSlice";
import { noticeSlice } from "../app/pages/notice/_redux/noticeSlice";
import { persistedNotificationReducer } from "../app/pages/notification/_redux/notificationSlice";
import { assignTemplateSlice } from "../app/pages/off-day/assign-list/_redux/assignTemplateSlice";
import { offDayApplySlice } from "../app/pages/off-day/off-day-apply/_redux/offDayApplySlice";
import { offDayHistorySlice } from "../app/pages/off-day/off-day-history/_redux/offDayHistorySlice";
import { templateSlice } from "../app/pages/off-day/template/_redux/templateSlice";
import { organogramSlice } from "../app/pages/organogram/_redux/organogramSlice";
import { overTimeAssignTemplateSlice } from "../app/pages/over-time/assign-list/_redux/assignTemplateSlice";
import { overTimeHistorySlice } from "../app/pages/over-time/over-time-history/_redux/overTimeHistorySlice";
import { overTimeTemplateSlice } from "../app/pages/over-time/template/_redux/templateSlice";
import { payrollAssignTemplateSlice } from "../app/pages/payroll/assign-list/_redux/assignTemplateSlice";
import { payrollTemplateSlice } from "../app/pages/payroll/template/_redux/templateSlice";
import { perfomSlice } from "../app/pages/performance/_redux/perfomSlice";
import { featurePermissionsSlice } from "../app/pages/permission/_redux/featurerPermissionSlice";
import { policySlice } from "../app/pages/policy/_redux/policySlice";
import { recruitementSlice } from "../app/pages/recruitement/_redux/recruitementSlice";
import { requisitonSlice } from "../app/pages/requisition/_redux/requisitonSlice";
import { rulesSlice } from "../app/pages/rules/_redux/rulesSlice";
import { shiftSlice } from "../app/pages/shift/_redux/shiftSlice";
import { shiftMappingSlice } from "../app/pages/shift/shift-mapping/_redux/shiftMappingSlice";
import { taxSlice } from "../app/pages/tax-management/_redux/taxSlice";
import { wageSlice } from "../app/pages/wage-management/_redux/wageSlice";
import { weekendSlice } from "../app/pages/weekend/_redux/weekendSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  requisiton: requisitonSlice.reducer,
  recruitement: recruitementSlice.reducer,
  jobDescription: jobDescriptionSlice.reducer,
  applicant: applicantSlice.reducer,
  rules: rulesSlice.reducer,
  shift: shiftSlice.reducer,
  shiftMapping: shiftMappingSlice.reducer,
  template: templateSlice.reducer,
  assignTemplate: assignTemplateSlice.reducer,
  overTimeAssignTemplate: overTimeAssignTemplateSlice.reducer,
  offDayHistory: offDayHistorySlice.reducer,
  offDayApply: offDayApplySlice.reducer,
  holidaySetup: holidaySetupSlice.reducer,
  overTimeTemplate: overTimeTemplateSlice.reducer,
  overTimeHistory: overTimeHistorySlice.reducer,
  payrollTemplate: payrollTemplateSlice.reducer,
  payrollAssignTemplate: payrollAssignTemplateSlice.reducer,
  delegation: delegationSlice.reducer,
  employeeList: employeeSlice.reducer,
  featurePermission: featurePermissionsSlice.reducer,
  weekend: weekendSlice.reducer,
  organogram: organogramSlice.reducer,
  wageManager: wageSlice.reducer,
  attendance: attendanceSlice.reducer,
  leave: leaveSlice.reducer,
  policy: policySlice.reducer,
  notice: noticeSlice.reducer,
  perfomance: perfomSlice.reducer,
  tax: taxSlice.reducer,
  loanManager: loanSlice.reducer,
  letterManage: persistedLetterReducer,
  notification: persistedNotificationReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../helpers/devApi";
import { swalError, swalSuccess } from "../../helpers/swal";

const ChangePassword = () => {
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");

    const [formData, setFormData] = useState({
        current_password: "",
        new_password: "",
        confirm_password: "",
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();

        setAlertMsg();
        setIsAlert(false);

        let valid = true;
        var msg = "";
        if (formData.new_password && formData.confirm_password) {
            if (formData.new_password != formData.confirm_password) {
                msg = "New Password & Confirm Password must match.";
                valid = false;
                setIsAlert(true);
                setAlertMsg(msg);
            }
        }
        if (!formData.confirm_password) {
            msg = "Confirm Password not be left blank.";
            valid = false;
            setIsAlert(true);
            setAlertMsg("Confirm password not be left blank");
        }
        if (!formData.new_password) {
            msg = "New Password not be left blank.";
            valid = false;
            setIsAlert(true);
            setAlertMsg(msg);
        }
        if (!formData.current_password) {
            valid = false;
            msg = "Current Password not be left blank. ";
            setIsAlert(true);
            setAlertMsg(msg);
        }

        var error_msg = "System has failerd to connect due to internet problem.";
        var sccs_msg = "Password Updated Successfully.";

        if (valid) {
            if (user) {
                setLoading(true);
                await API.post(`/ade_users/passwordReset/${user.user_id}`, {
                    current_password: formData.current_password,
                    new_password: formData.new_password,
                })
                    .then(async (res) => {
                        setLoading(false);
                        if (res.data.success) {
                            localStorage.removeItem('CORE_MODULE_AUTH');
                            localStorage.removeItem('user');
                            localStorage.removeItem('AUTH');
                            localStorage.removeItem('menuType');
                            localStorage.removeItem('persist:letter');
                            localStorage.removeItem('persist:unitoken');
                            localStorage.removeItem('persist:notification');

                            swalSuccess(res.data.data?.message);
                            swalSuccess(res.data.data);

                            window.location.href = '/';

                            // swalSuccess(sccs_msg);
                        } else {
                            swalError(res.message);
                            // swalError(error_msg);
                        }
                    })
                    .catch((error) => {
                        swalError(error_msg);
                    });
            } else {
                swalError(error_msg);
            }
        }
    };

    return (
        <div className="row">
            <div className="col-md-7 mx-auto mt-5">
                <div className="card p-3">
                    <div className="alert change-password-box text-align-center">
                        <h2> Change Password </h2>
                    </div>
                    <div className="pt-3 form-box">
                        <form>
                            <div className="row pt-3">
                                <div className="col-md-3">
                                    <label className="form-control-label">
                                        <strong> Current Password  : <span style={{ color: "red" }}>*</span> </strong>
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <input
                                        type="password"
                                        name="current_password"
                                        onChange={(e) => handleChange(e)}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-3">
                                    <label className="form-control-label">
                                        <strong> New Password
                                            : <span style={{ color: "red" }}>*</span>
                                        </strong>
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <input
                                        type="password"
                                        name="new_password"
                                        onChange={(e) => handleChange(e)}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-3">
                                    <label className="form-control-label">
                                        <strong> Confirm Password
                                            : <span style={{ color: "red" }}>*</span>
                                        </strong>
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <input
                                        type="password"
                                        name="confirm_password"
                                        onChange={(e) => handleChange(e)}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="row pt-3">
                                {isAlert && (
                                    <div className="col-md-4 alert password-warning">
                                        <i className="fa-solid fa-triangle-exclamation triangle-exclamation"></i>
                                        &nbsp;
                                        <strong>{alertMsg}</strong>
                                    </div>
                                )}
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-9"></div>
                                <div className="col-md-3">
                                    <button
                                        type="button"
                                        disabled={loading}
                                        onClick={(e) => handleSubmit(e)}
                                        className="btn btn-default"
                                    // style={{ marginLeft: "130px" }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default ChangePassword;

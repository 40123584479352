import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

const About = () => {
    return (
        <>
            <div className="container-fluid mt-5">

            
                <div className="row">
                    <div className="col-md-12">
                        <div className="card about_page_card">
                            <div className="card-header">
                                <h5 className="title_h5"> Software Description </h5>
                            </div>

                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mt-2">
                                            <h5 className="mb-4 italic_title_h5">Integrated modules are coming soon...</h5>
                                            <div className="border">
                                                <img className="img-fluid" src={toAbsoluteUrl("/media/logos/home_page.png")} />
                                            </div> 
                                        </div>

                                        <div className="mt-5">
                                            <h5 className="mb-4 title_h5"> <strong> B-Track </strong> Work Flow</h5>
                                            <div className="border">
                                                <img className="img-fluid" src={toAbsoluteUrl("/media/logos/work-flow.png")} />
                                            </div>
                                        </div> 
                                    </div>

                                    <div className="col-md-8 mt-5">
                                        <div className="card-body border about_content_card">
                                            <p>
                                                <strong> B-Track </strong> is a complete solution for BRTC to track buses details information with different types of accounting report. There are few steps to track all buses activity and accounting details. It can easily track below activities:
                                            </p> 
                                            <ul>
                                                <li>Can track bus details which are on repair, which are on route.</li>
                                                <li>Can easily track income of individual bus. It's may have on route income or different route income.</li>
                                                <li>You can easily compare cost vs income of every bus.</li>
                                                <li>Easily can generate different types of report.</li>
                                            </ul>

                                            <div className="my-5">
                                                <h5> Reports are: </h5> 
                                                <ul>
                                                    <li>Daily Bus Log Report</li>
                                                    <li>Daily Bus Position Report</li>
                                                    <li>Daily Income Report</li>
                                                    <li>Payslip Report which are used or not</li>
                                                    <li>Monthly Individual Bus Report Route Wise</li>
                                                    <li>Monthly Income Report Route Wise</li>
                                                    <li>Monthly Income Report Bus Type Wise</li> 
                                                </ul>
                                            </div>

                                            <div className="my-5">
                                                <h5> Modules are: </h5> 
                                                <ul>
                                                    <li>Bus history tracking</li>
                                                    <li>Accounting</li>
                                                    <li>Store Management</li>
                                                    <li>Reporting</li> 
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>


                <div className="row mt-5">
                    <div className="col-md-12">
                        <div className="card about_page_card">
                            <div className="card-header">
                                <h5 className="title_h5"> Technology Description </h5>
                            </div>

                            <div className="card-body">
                                <h3 className="italic_title_h5"> Used technology </h3> 
                                <ul>
                                    <li>Angular 4</li>
                                    <li>Web API, C#</li>
                                    <li>EF latest version</li>
                                    <li>SQL Server 2014</li>
                                    <li>Bootstrap 4</li> 
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-12">
                        <div className="card about_page_card">
                            <div className="card-header">
                                <h5 className="title_h5"> Contact Information </h5>
                            </div>

                            <div className="card-body">
                                <h3 className="italic_title_h5"> Contact Details </h3> 
                                <ul>
                                    <li><strong>Supervised By : </strong>Md. Moniruzzaman, Manager Operation, Joarshahara Bus Depot, BRTC.</li>
                                    <li><strong>Requirement Analyst :</strong> Palash Kanti Bachar, Md. Mirajul Islam (Joarshahara Bus Depot, BRTC)</li>
                                    <li><strong>Developed By:</strong> Option B</li>
                                    <li><strong>Contact Person : </strong> <a className="text-info" target="_blank" href="http://kpalash.com/">Palash Kanti Bachar</a></li>
                                    <li><strong>Contact :</strong> +8801924332931</li> 
                                </ul>
                                <p className="italic_title_h5">Feel free to contact with this person if needed.</p>
                            </div>

                        </div>
                    </div>
                </div>
                 
            </div>
        </>

    );
};

export default About;

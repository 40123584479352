/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import objectPath from "object-path";
import React, { useMemo, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { actions } from "../../../../../app/modules/Auth/redux/authRedux";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import API from "../../../../../app/helpers/devApi";
import ERPAPI from "../../../../../app/helpers/erpDevApi";
import Axios from "axios";
export function UserProfileDropdown() {
    // const {
    //   user: { data },
    // } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [showDropdown, setShowDropdown] = useState(false);
    const { user, authToken, coreModuleInfo } = useSelector((state) => state.auth);
    const fromErp = localStorage.getItem("fromErp");

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            light:
                objectPath.get(uiService.config, "extras.user.dropdown.style") ===
                "light",
        };
    }, [uiService]);

    const logoutClick = async () => {
        //only for erp logout
        if (fromErp && fromErp) {
            await ERPAPI.post("/auth/logout", {}).then((res) => {
                // localStorage.clear();
                dispatch(actions.logout());
                // window.location.href = `${process.env.REACT_APP_ERP_FE}`;
                if (document.visibilityState === "hidden") {
                    localStorage.clear();
                }
                localStorage.clear();
            });
        } else {
            localStorage.clear();
            dispatch(actions.logout());
        }
    };


    return (
        <Dropdown
            drop="down"
            alignRight
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
        >
            <Dropdown.Toggle
                as={DropdownTopbarItemToggler}
                id="dropdown-toggle-user-profile"
            >
                <div
                    className={
                        "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 ml-5"
                    }
                >
                    <div className="d-flex flex-column">
                        <h2 className="font-size-base ml-auto user_name topbar-helper">
                            {authToken?.full_name?.split(" ")[0]}
                        </h2>
                        <p className="text-muted user_email">{authToken?.email}</p>{" "}
                    </div>
                    <div className="user_image">
                        <img
                            src="https://bracuerp-dev.apsissolutions.com/images/user.png"
                            alt=""
                        />
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
                show={showDropdown}
            >
                <>
                    {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
                    {layoutProps.light && (
                        <>
                            <div className="d-flex align-items-center p-8 rounded-top">
                                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                                    <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                                </div>
                                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                                    {authToken?.full_name}
                                </div>
                                <button
                                    className="btn btn-light-primary btn-bold"
                                    onClick={logoutClick}
                                >
                                    Sign out
                                </button>
                            </div>
                            <div className="separator separator-solid"></div>
                        </>
                    )}
                </>
                {/* <div>
           <div className="symbol symbol-md mt-5 d-flex border rounded-circle justify-content-center mx-auto">
           {company.image ? (
                   <img src={`data:image/png;base64,${company.image}`} alt="" />
                ) : (
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                )}
        </div>
           </div> */}

                <div className="navi navi-spacer-x-0">
                    <Link to="/change-password" className="navi-item px-5">
                        <div className="navi-link">
                            <div className="navi-text">
                                <div className="text-muted user_dropdown">Settings</div>
                            </div>
                        </div>
                    </Link>

                    {/* employee_id */}

                    <Link to={`/employee/onboarding/view/${coreModuleInfo?.employee_id}`} className="navi-item px-5">
                        <div className="navi-link">
                            <div className="navi-text">
                                <div className="text-muted user_dropdown">Profile</div>
                            </div>
                        </div>
                    </Link>
                    {/* <Link to="/profile" className="navi-item px-5">
            <div className="navi-link">
              <div className="navi-text">
                <div className="text-muted user_dropdown">Profile</div>
              </div>
            </div>
          </Link> */}

                    <Link to="/notifications" className="navi-item px-5">
                        <div className="navi-link">
                            <div className="navi-text">
                                <div className="text-muted user_dropdown">Notifications</div>
                            </div>
                        </div>
                    </Link>

                    <div className="navi-separator mt-3"></div>

                    <Link to="/help" className="navi-item px-5">
                        <div className="navi-link">
                            <div className="navi-text">
                                <div className="text-muted user_dropdown">Help?</div>
                            </div>
                        </div>
                    </Link>

                    <div
                        className="navi-item px-5"
                        onClick={logoutClick}
                        style={{ cursor: "pointer" }}
                    >
                        <div className="navi-link">
                            <div className="navi-text">
                                <div className="text-muted user_dropdown">Signout</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  holidayForEdit: undefined,
  error: null,
  holidayTargetGroups: null,
  holidayType: null,
  selectedHolidayTargetGroups: [],
  start_date: null,
  end_date: null,
  applyEveryYear: null,
  holidayRemark: null,
  holidayTypeList: null,
  totalHolidays: 0,
  data: [],
  holidayByuser: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const holidaySetupSlice = createSlice({
  name: "holidaySetup",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getrequisitonById
    holidayFetched: (state, action) => {
      state.actionsLoading = false;
      state.holidayForEdit = action.payload.holidayForEdit;
      state.error = null;
    },

    holidayTypeListFetched: (state, action) => {
      state.actionsLoading = false;
      state.holidayTypeList = action.payload.holidayTypeList;
      state.error = null;
    },

    holidayTargetGroupFetched: (state, action) => {
      state.actionsLoading = false;
      state.holidayTargetGroups = action.payload.holidayTargetGroups;
      state.error = null;
    },

    holidayTypeFetched: (state, action) => {
      state.actionsLoading = false;
      state.holidayType = action.payload.holidayType;
      state.error = null;
    },

    holidayTypeByUserFetched: (state, action) => {
      state.actionsLoading = false;
      state.holidayByuser = action.payload.holidayByuser;
      state.error = null;
    },

    //  holidayStartDayFetched: (state, action) => {
    //    state.actionsLoading = false;
    //    state.designation = action.payload.designation;
    //    state.error = null;
    //  },

    //  departmentFetched: (state, action) => {
    //    state.actionsLoading = false;
    //    state.departments = action.payload.departments;
    //    state.error = null;
    //  },

    //  personByDeptFetched: (state, action) => {
    //    state.actionsLoading = false;
    //    state.personByDept = action.payload.personByDept;
    //    state.error = null;
    //  },

    //  workstationFetched: (state, action) => {
    //    state.actionsLoading = false;
    //    state.workstation = action.payload.workstation;
    //    state.error = null;
    //  },

    //  // find requisiton
    allHolidaysFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    holidaySetupUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.holidayForEdit = undefined;
    },

    holidaySetupDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  actionsLoading: false,
  organogramData: [],
  departments: [],
};
export const callTypes = {
    list: "list",
    action: "action",
};

export const organogramSlice = createSlice({
    name: "organogram",
    initialState: initialState,
    reducers: {
      catchError: (state, action) => {
        state.error = `${action.type}: ${action.payload.error}`;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = false;
        } else {
          state.actionsLoading = false;
        }
      },
  
      startCall: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },
      updateOrganogramData: (state, action)=>{
        state.error = null;
        state.actionsLoading = false;
        state.organogramData = action.payload;
      },
      updateDepartmentData: (state, action)=>{
        state.error = null;
        state.actionsLoading = false;
        state.departments = action.payload;
      }
    }
    })


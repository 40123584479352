import React, { useState, useRef, useEffect } from "react";
import { IoSearchOutline } from "react-icons/io5";

const MultiSelectDropdown = ({
  placeholder = "options",
  options = [],
  onChange,
  value = [],
  disabled,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);

  const filteredOptions = options.filter((option) =>
    option.label?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const updatedSelection = [...selectedOptions];

    if (
      updatedSelection.some(
        (selectedOption) => selectedOption.value === option.value
      )
    ) {
      updatedSelection.splice(
        updatedSelection.findIndex(
          (selectedOption) => selectedOption.value === option.value
        ),
        1
      );
    } else {
      updatedSelection.push(option);
    }

    setSelectedOptions(updatedSelection);
    const onlyVal = updatedSelection?.map((item) => item.value);
    onChange(onlyVal);
  };

  const handleSelectAll = () => {
    setSelectedOptions([...options]);
    const onlyVal = options?.map((item) => item.value);
    onChange([...onlyVal]);
  };

  const handleUnselectAll = () => {
    setSelectedOptions([]);
    onChange([]);
  };

  const handleDocumentClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (value && value.length > 0) {
      const filteredData = options?.filter((item) =>
        value?.includes(item.value)
      );
      setSelectedOptions(filteredData);
    } else {
      setSelectedOptions([]);
    }
  }, [value]);

  const getSelectedCount = () => {
    if (selectedOptions.length === 0) {
      return `Select ${placeholder}`;
    } else if (selectedOptions.length === 1) {
      return `1 ${placeholder} selected`;
    } else if (selectedOptions.length === options.length) {
      return `All ${placeholder} selected (${selectedOptions.length})`;
    } else {
      return `${selectedOptions.length} ${placeholder} selected`;
    }
  };

  return (
    <div
      className={`multi-select-dropdown ${disabled ? "disabled" : ""} ${
        isOpen ? "open" : ""
      }`}
      ref={dropdownRef}
    >
      <div className="dropdown-header" onClick={handleToggle}>
        {getSelectedCount()}
      </div>
      {isOpen && (
        <div className="dropdown-options">
          <div className="search-group border-bottom">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              className="form-control"
              autoFocus
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <IoSearchOutline />
          </div>
          <div className="option-list">
            {options.length > 0 &&
            searchQuery == "" &&
            selectedOptions.length === options.length ? (
              <div
                className={`option ${
                  selectedOptions.length === 0 ? "selected" : ""
                }`}
                onClick={handleUnselectAll}
              >
                Unselect All
              </div>
            ) : (
              options.length > 0 &&
              searchQuery == "" && (
                <div
                  className={`option ${
                    selectedOptions.length === options.length ? "selected" : ""
                  }`}
                  onClick={handleSelectAll}
                >
                  Select All
                </div>
              )
            )}

            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className={`option ${
                    selectedOptions.some(
                      (selectedOption) => selectedOption.value === option.value
                    )
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </div>
              ))
            ) : (
              <div className="no-results text-center">No results found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;

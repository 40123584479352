import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  ruleCompanyEntities: null,
  lastError: null,
  rulesForEdit: undefined,
  rulesCompanyForEdit: undefined,
  error: null,
  dropdown: null,
  company: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const rulesSlice = createSlice({
  name: "rules",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    rulesFetched: (state, action) => {
      state.actionsLoading = false;
      state.rulesForEdit = action.payload.rulesForEdit;
      state.error = null;
    },

    rulesCompanyFetched: (state, action) => {
      state.actionsLoading = false;
      state.rulesCompanyForEdit = action.payload.rulesCompanyForEdit;
      state.error = null;
    },

    rulesDropdown: (state, action) => {
      state.actionsLoading = false;
      state.dropdown = action.payload.dropdown;
      state.error = null;
    },

    companyDropdown: (state, action) => {
      state.actionsLoading = false;
      state.company = action.payload.company;
      state.error = null;
    },

    allRulesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    allComapnyRulesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.ruleCompanyEntities = entities;
      state.totalCount = totalCount;
    },

    // delete requisiton
    rulesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});

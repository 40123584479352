import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  templateForEdit: undefined,
  error: null,
  templateDropdown: null,
  earningHead: null,
  deductionHead: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const payrollTemplateSlice = createSlice({
  name: "payrollTemplate",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    allTemplateFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // getrequisitonById
    templateFetched: (state, action) => {
      state.actionsLoading = false;
      state.templateForEdit = action.payload.templateForEdit;
      state.error = null;
    },

    templateDropdown: (state, action) => {
      state.actionsLoading = false;
      state.templateDropdown = action.payload.templateDropdown;
      state.error = null;
    },

    earningHeadFetched: (state, action) => {
      state.actionsLoading = false;
      state.earningHead = action.payload.earningHead;
      state.error = null;
    },

    deductionHeadFetched: (state, action) => {
      state.actionsLoading = false;
      state.deductionHead = action.payload.deductionHead;
      state.error = null;
    },
  },
});

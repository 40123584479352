import API from "../../../helpers/devApi";

const API_ENDPOINTS = "/hrms_recruitment_requisition";

export function getAllTemplateVariable() {
  return API.get(`/hrms_letter_template/get/variable/for/template`);
}

export function getAllTemplateType() {
  return API.get(`/hrms_letter_template_type`);
}

export function getAllHeaderFooter(companyId) {
  return API.get(`/sys_branchs/${companyId}`);
}

export function getAllEventActionList() {
  return API.get(`/hrms_notification_event_actions`);
}

export function getEventOne(id) {
  return API.get(`/hrms_notification_event/${id}`);
}

export function getNotificationEventList(payload) {
  return API.post(`/hrms_notification_event/geAllEvent`, payload);
}

export function getLetterOne(id) {
  return API.get(`/hrms_letter_template/${id}`);
}

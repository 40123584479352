import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  jobDescriptionForEdit: undefined,
  error: null,
  dropdown: null,
  questions: null,
  questionType: null,
  generalDuty: {},
  skills: [],
  certifications: [],
  employeeCategory: [],
  marks: null,
  jobListDropdown: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const jobDescriptionSlice = createSlice({
  name: "jobDescription",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    jobDescriptionFetched: (state, action) => {
      state.actionsLoading = false;
      state.jobDescriptionForEdit = action.payload.jobDescriptionForEdit;
      state.error = null;
    },

    jobDescriptionDropdown: (state, action) => {
      state.actionsLoading = false;
      state.dropdown = action.payload.dropdown;
      state.error = null;
    },

    jobListDropdown: (state, action) => {
      state.actionsLoading = false;
      state.jobListDropdown = action.payload.jobListDropdown;
      state.error = null;
    },

    jobDescriptionQuestions: (state, action) => {
      state.actionsLoading = false;
      state.questions = action.payload.questions;
      state.error = null;
    },

    jobDescriptionMarks: (state, action) => {
      state.actionsLoading = false;
      state.marks = action.payload.marks;
      state.error = null;
    },

    fetchGeneralDuty: (state, action) => {
      state.actionsLoading = false;
      state.generalDuty = action.payload.generalDuty;
      state.error = null;
    },

    jobDescriptionQuestionType: (state, action) => {
      state.actionsLoading = false;
      state.questionType = action.payload.questionType;
      state.error = null;
    },

    jobDescriptionAssessmentsQuestions: (state, action) => {
      state.actionsLoading = false;
      state.assessmentsQuestions = action.payload.assessmentsQuestions;
      state.error = null;
    },

    jobDescriptionSkills: (state, action) => {
      state.actionsLoading = false;
      state.skills = action.payload.skills;
      state.error = null;
    },

    employeeCategoryFetch: (state, action) => {
      state.actionsLoading = false;
      state.employeeCategory = action.payload.employeeCategory;
      state.error = null;
    },

    jobDescriptionCertifications: (state, action) => {
      state.actionsLoading = false;
      state.certifications = action.payload.certifications;
      state.error = null;
    },

    allJobDescriptionFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // delete requisiton
    jobDescriptionDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el) => el.id !== action.payload.id
      );
    },
  },
});

import { Drawer, Switch } from "antd";
import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { AiOutlineGroup, AiOutlineInfoCircle } from "react-icons/ai";
import { MdOutlineNoteAlt } from "react-icons/md";
import { TbTriangleSquareCircle } from "react-icons/tb";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import API from "../../../../app/helpers/devApi";
import OneSignal from "react-onesignal";

export function Topbar({ handle }) {
  const history = useHistory();
  const { headerFooter, auth } = useSelector(
    ({ notification, auth }) => ({
      headerFooter: notification.headerFooter,
      auth: auth.coreModuleInfo,
    }),
    shallowEqual
  );

  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );

  const [darkAside, setDarkAside] = useLocalStorage("light-aside");

  const [open, setOpen] = useState(false);
  const [size, setSize] = useState("250px");
  const showDefaultDrawer = () => {
    setSize("250px");
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  const themeData = useMemo(() => {
    return headerFooter && JSON.parse(headerFooter?.color_json);
  }, [headerFooter]);

  useEffect(() => {
    if (darkAside == true) {
      document.documentElement.style.setProperty("--aside-bg-color", "#1e1e2d");
      document.documentElement.style.setProperty(
        "--aside-list-bg-color",
        "#1e1e2d"
      );
      document.documentElement.style.setProperty(
        "--aside-active-gradient",
        "linear-gradient(270deg, #DE6262 0%, #FFB88C 100%)"
      );
    } else {
      document.documentElement.style.setProperty(
        "--aside-bg-color",
        themeData?.aside_bg_color ?? "#0050b3"
      );
      document.documentElement.style.setProperty(
        "--aside-list-bg-color",
        themeData?.aside_list_bg_color ?? "#0078d4"
      );
      document.documentElement.style.setProperty(
        "--aside-active-gradient",
        themeData?.aside_active_gradient ??
          "linear-gradient(270deg, #004578 0%, #0078d4 100%)"
      );
      document.documentElement.style.setProperty(
        "--btn-primary",
        themeData?.primary ?? "#bd4045"
      );
      document.documentElement.style.setProperty(
        "--btn-secondary",
        themeData?.secondary ?? "#fff"
      );
      document.documentElement.style.setProperty(
        "--table-header-bg-color",
        themeData?.table_header_bg_color ?? "#0050b3"
      );
      // document.documentElement.style.setProperty("--aside-bg-color", "#0050b3");
      // document.documentElement.style.setProperty(
      //   "--aside-list-bg-color",
      //   "#0078d4"
      // );
      // document.documentElement.style.setProperty(
      //   "--aside-active-gradient",
      //   "linear-gradient(270deg, #DE6262 0%, #FFB88C 100%)"
      // );
    }
  }, [darkAside, themeData]);

  const [initialized, setInitialized] = useState(false);

  // useEffect(() => {
  //     const subscibe = async (userid, subid) => {
  //         const payload = {
  //             employee_id: userid,
  //             device_key: subid,
  //             device_type: "web",
  //         };
  //         const { data } = await API.post(
  //             `/hrms_employee/update/employee/player/id`,
  //             payload
  //         );
  //     };
  //     if (!!auth) {
  //         OneSignal.init({
  //             appId: "e000b78d-f332-4448-a0bb-4dfdb97b20ea",
  //             allowLocalhostAsSecureOrigin: true,
  //             serviceWorkerParam: {
  //                 scope:
  //                     // "https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js",
  //                     // "https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.sw.js",
  //                     "https://cdn.onesignal.com/sdks/OneSignalSDKWorker.js",
  //             },
  //         })
  //             .then(() => {
  //                 console.log(
  //                     "```````````````````Init DOne```````````````````"
  //                 );
  //                 setInitialized(true);
  //                 OneSignal.showSlidedownPrompt().then(() => {
  //                     console.log("one signal");
  //                     console.log(
  //                         "```````````````````Init showSlidedownPrompt DONE```````````````````"
  //                     );
  //                 });
  //             })
  //             .catch((error) => {
  //                 console.error("--------Init or SHOW error");
  //                 console.error(error);
  //             });

  //         console.log("initalized");
  //         console.log(initialized);

  //         if (initialized && auth) {
  //             OneSignal.isPushNotificationsEnabled(function(isEnabled) {
  //                 console.log("~~~isEnabled");
  //                 console.log(isEnabled);

  //                 if (isEnabled) {
  //                     console.log(
  //                         "~~~~~~~~~~~~~~~~~~~~~~~Push notifications are enabled!"
  //                     );
  //                     OneSignal.getUserId(function(userId) {
  //                         subscibe(auth.employee_id, userId);
  //                         // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
  //                     });
  //                 } else {
  //                     console.log(
  //                         "...........................Push notifications are not enabled!"
  //                     );
  //                 }
  //             });
  //         }
  //     }
  // }, [initialized, auth]);

  //   useEffect(() => {
  //     const subscibe = async (userid, subid) => {
  //       const payload = {
  //         employee_id: userid,
  //         device_key: subid,
  //         device_type: "web",
  //       };
  //       const { data } = await API.post(
  //         `/hrms_employee/update/employee/player/id`,
  //         payload
  //       );
  //     };
  //     if (!!auth) {
  //       OneSignal.init({
  //         appId: "cb043de4-fd1b-4f1d-8fee-7ec4b02ca5a3",
  //         allowLocalhostAsSecureOrigin: true,
  //         serviceWorkerParam: {
  //           scope: "https://cdn.onesignal.com/sdks/OneSignalSDKWorker.js",
  //         },
  //         notifyButton: {
  //           enable: true,
  //         },
  //         subdomainName: "hrms-formonix",
  //       })
  //         .then(() => {
  //           setInitialized(true);
  //           OneSignal.showSlidedownPrompt().then(() => {
  //             console.log("one signal");
  //             setInitialized(true);
  //           });
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });

  //       console.log("====================================");
  //       console.log(initialized, auth);
  //       console.log("====================================");
  //       if (initialized && auth) {
  //         OneSignal.isPushNotificationsEnabled(function(isEnabled) {
  //           console.log({ isEnabled });
  //           //   if (isEnabled) {
  //           //console.log("Push notifications are enabled!");
  //           OneSignal.getUserId(function(userId) {
  //             console.log("OneSignal User ID:", userId);
  //             subscibe(auth.employee_id, userId);
  //             // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
  //           });
  //           //   } else {
  //           //     console.log("Push notifications are not enabled!");
  //           //   }
  //         });
  //       }
  //     }
  //   }, [initialized, auth]);
  useEffect(() => {
    const subscribe = async (userid, subid) => {
      try {
        const payload = {
          employee_id: userid,
          device_key: subid,
          device_type: "web",
        };
        const { data } = await API.post(
          `/hrms_employee/update/employee/player/id`,
          payload
        );
        console.log("Subscription successful:", data);
      } catch (error) {
        console.error("Subscription failed:", error);
      }
    };

    if (!!auth) {
      OneSignal.init({
        appId: "e000b78d-f332-4448-a0bb-4dfdb97b20ea",
        allowLocalhostAsSecureOrigin: true,
        serviceWorkerParam: {
          scope: "https://cdn.onesignal.com/sdks/OneSignalSDKWorker.js",
        },
        // notifyButton: {
        //   enable: true,
        // },
        // subdomainName: "hrms-formonix",
      })
        .then(() => {
          setInitialized(true);
          return OneSignal.showSlidedownPrompt();
        })
        .then(() => {
          console.log("OneSignal prompt shown");
          setInitialized(true);
        })
        .catch((error) => {
          console.error("OneSignal initialization failed:", error);
        });

      if (initialized && auth) {
        OneSignal.isPushNotificationsEnabled(async function(isEnabled) {
          console.log({ isEnabled });
          if (isEnabled) {
            OneSignal.getUserId(function(userId) {
              console.log("OneSignal User ID:", userId);
              subscribe(auth.employee_id, userId);
            });
          } else {
            console.log("Push notifications are not enabled!");
            // Requesting permission explicitly
            const permission = await OneSignal.showNativePrompt();
            if (permission === "granted") {
              console.log("Notifications permission granted.");
            } else {
              console.log("Notifications permission not granted.");
            }
          }
        });
      }
    }
  }, [initialized, auth]);

  function setAsideColor(bgColor, listColor, gradient) {
    document.documentElement.style.setProperty("--aside-bg-color", bgColor);
    document.documentElement.style.setProperty(
      "--aside-list-bg-color",
      listColor
    );
    document.documentElement.style.setProperty(
      "--aside-active-gradient",
      gradient
    );
  }

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/service-worker.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    });
  }

  navigator.serviceWorker
    .register("/service-worker.js", { scope: "/" })
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    });

  navigator.serviceWorker.ready.then((registration) => {
    console.log("Service Worker is ready:", registration);
  });

  return (
    <div className="topbar">
      {/* {layoutProps.viewSearchDisplay && <SearchDropdown />}

      {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}

      {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />} */}

      {/* {layoutProps.viewCartDisplay && <MyCartDropdown />} */}

      {/* {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Quick panel"
            data-placement="right"
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )} */}

      {/* <span
                style={{
                    marginTop: "22px",
                    cursor: "pointer",
                    color: "rgba(0, 0, 0, 0.65)",
                }}
                className="mx-4 topbar-helper"
                onClick={() => {
                    window.location.href = `${process.env.REACT_APP_ERP_FE}/faq/help`;
                }}
            >
                <AiOutlineInfoCircle
                    style={{ fontSize: "18px" }}
                    className="topbar-helper"
                />{" "}
                Help
            </span> */}

      {/* <span style={{ marginTop: "12px", cursor: "pointer" }}>
                <Dropdown>
                    <Dropdown.Toggle
                        variant=""
                        style={{ color: "rgba(0, 0, 0, 0.65)" }}
                        className="topbar-helper"
                    >
                        <MdOutlineNoteAlt
                            style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.65)" }}
                            className="topbar-helper"
                        />{" "}
                        My Requisition
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => {
                                window.location.href = `${process.env.REACT_APP_ERP_FE}/supply-chain/my-requisition`;
                            }}
                        >
                            <AiOutlineGroup
                                style={{
                                    marginTop: "1px",
                                    fontSize: "16px",
                                    marginRight: "5px",
                                }}
                            />{" "}
                            Stock Requisition
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                window.location.href = `${process.env.REACT_APP_ERP_FE}/procurement/purchase-requisition`;
                            }}
                        >
                            <AiOutlineGroup
                                style={{
                                    marginTop: "1px",
                                    fontSize: "16px",
                                    marginRight: "5px",
                                }}
                            />{" "}
                            Purchase Requisition
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </span> */}

      {/* <span
                style={{
                    marginTop: "22px",
                    cursor: "pointer",
                    color: "rgba(0, 0, 0, 0.65)",
                }}
                className="mx-4 topbar-helper"
                onClick={() => {
                    window.location.href = `${process.env.REACT_APP_ERP_FE}/cc_viewer`;
                }}
            >
                <TbTriangleSquareCircle
                    style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.65)" }}
                    className="topbar-helper"
                />{" "}
                CC Viewer
            </span> */}

      {auth?.role !== "Employee" && (
        <span
          style={{
            marginTop: "22px",
            cursor: "pointer",
            color: "rgba(0, 0, 0, 0.65)",
          }}
          className="mx-4 topbar-helper"
          onClick={() => {
            // window.location.href = `${process.env.REACT_APP_ERP_FE}/delegation`;
            history.push("/delegation/pending-approval");
          }}
        >
          <TbTriangleSquareCircle
            style={{
              fontSize: "18px",
              color: "rgba(0, 0, 0, 0.65)",
            }}
            className="topbar-helper"
          />{" "}
          Pending Approval
        </span>
      )}

      {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}

      {/*<span
        style={{
          fontSize: "25px",
          marginTop: "10px",
          cursor: "pointer",
          marginRight: "25px",
        }}
        onClick={handle.active ? handle.exit : handle.enter}
      >
        <AiOutlineFullscreen />
      </span>*/}

      {/*<span
        style={{
          fontSize: "23px",
          marginTop: "12px",
          cursor: "pointer",
          marginRight: "15px",
        }}
        onClick={showDefaultDrawer}
      >
        <LuSettings />
      </span>*/}

      <Drawer
        title={`Settings2`}
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
        width={"300px"}
      >
        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-0" style={{ fontSize: "14px" }}>
                Dark sidebar menu
              </p>
              <Switch
                defaultChecked={darkAside == true ? true : false}
                size="small"
                onChange={(val) => {
                  setDarkAside(true);
                  if (val) {
                    setAsideColor(
                      "#1e1e2d",
                      "#1e1e2d",
                      "linear-gradient(270deg, #DE6262 0%, #FFB88C 100%)"
                    );
                  } else {
                    setAsideColor(
                      "#0050b3",
                      "#0078d4",
                      "linear-gradient(270deg, #DE6262 0%, #FFB88C 100%)"
                    );
                  }
                }}
              />
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-0" style={{ fontSize: "14px" }}>
                Weak colors
              </p>
              <Switch
                size="small"
                defaultChecked={theme == "dark" ? true : false}
                onChange={(val) => {
                  switchTheme();
                }}
              />
            </div>
          </div> */}
        </div>
      </Drawer>

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    actionsLoading: false,
    listLoading: false,
    listData: [],
    totalData: 0,
    categories: [],
    loan_types: [],
};
export const callTypes = {
    list: "list",
    action: "action",
};
export const loanSlice = createSlice( {
    name: "loanmanager",
    initialState: initialState,
    reducers: {
        catchError: ( state, action ) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if ( action.payload.callType === callTypes.list )
            {
                state.listLoading = false;
            } else
            {
                state.actionsLoading = false;
            }
        },
        startCall: ( state, action ) => {
            state.error = null;
            if ( action.payload.callType === callTypes.list )
            {
                state.listLoading = true;
            } else
            {
                state.actionsLoading = true;
            }
        },
        setListData: (state, action) =>{
            state.error = null;
            state.listData = action.payload.listData;
            state.totalData = action.payload.totalData;
            state.listLoading = false;
            
        },
        setEmployeeTypeNDesignationNCategory: (state, action)=>{
            state.designations = action.payload.designations;
            state.categories = action.payload.categories;
            state.types = action.payload.types;
        }
    }
} );
import { useFormik } from "formik";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Erplogin, login } from "../redux/authCrud";
import * as auth from "../redux/authRedux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import API from "../../../helpers/devApi";
import jwtDecode from "jwt-decode";

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    email: "",
    password: "",
};

function Login(props) {
    const dispatch = useDispatch()
    const [companyList, setCompanyList] = useState([]);
    const { intl } = props;
    const { authToken, loading } = useSelector((state) => state.auth);
    const [error, setError] = useState("");
    const [companyId, setCompanyId] = useState("");

    // useLayoutEffect(() => {
    //   if (authToken && Object.keys(authToken).length > 0) {
    //     props.history.push("/dashboard");
    //   } else {
    //     setTimeout(() => {
    //       window.location.href = `${process.env.REACT_APP_ERP_FE}`;
    //     }, 5000);
    //   }
    // }, [authToken, props.history]);

    const LoginSchema = Yup.object().shape({
        // email: Yup.string()
        //   .email("Wrong email format")
        //   .min(3, "Minimum 3 symbols")
        //   .max(50, "Maximum 50 symbols")
        //   .required(),

        email: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(),
        password: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(),
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,

        validationSchema: LoginSchema,
        onSubmit: (values, { resetForm, setSubmitting, setStatus }) => {
            Erplogin(values.email, values.password)
                .then(async (res) => {
                    if (!res?.data?.error) {
                        await login(values.email, values.password).then((core) => {
                            if (core.data.success) {
                                const coreModuleInfo = jwtDecode(core.data?.data?.access_token);
                                coreModuleInfo.token = core.data?.data?.access_token;
                                localStorage.setItem("CORE_MODULE_AUTH", core.data?.data?.access_token);
                                dispatch(props.login(res.data));
                                dispatch(props.coreModuleLogin(coreModuleInfo));
                            }
                        })
                    } else {
                        setError(res.message);
                    }
                })
                .catch(() => {
                    setError("Something went wrong. Please try again ");
                })
                .finally(() => {
                    setSubmitting(false);
                });
            resetForm();
        },
    });

    const getCompanyList = async () => {
        await API.get("/companies")
            .then((res) => {
                if (!res.data.error) {
                    if (res.data.data) {
                        setCompanyList(res.data.data.Companies);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => { 
        localStorage.clear();
    }, []);

    useLayoutEffect(() => { }, []);

    return <div
        className="login-form login-signin"
        id="kt_login_signin_form"
        style={{ backgroundColor: "#fff", borderRadius: "30px", padding: "30px" }}
    >
        {/* begin::Head */}
        <div className="text-center mb-10 mb-lg-20">
            <img
                alt="Logo"
                className="max-h-100px bengalmeat-logo pb-5"
                src={toAbsoluteUrl("/media/logos/logo_icon.png")}
            />
            <h3 className="font-size-h1">
                <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
            {/* <p className=" font-weight-bold">(FORMONIX-HRMS)</p> */}
        </div>
        {/* end::Head */}

        {/*begin::Form*/}
        <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
        >
            {error && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{error}</div>
                </div>
            )}

            {/* <div className="form-group fv-plugins-icon-container">
      <input
        placeholder="Email"
        type="email"
        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
          "email"
        )}`}
        name="email"
        {...formik.getFieldProps("email")}
      />
      {formik.touched.email && formik.errors.email ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{formik.errors.email}</div>
        </div>
      ) : null}
    </div> */}
            {/* <div className="form-group fv-plugins-icon-container">
      <select
        name="company_id"
        className={`form-control form-control-solid  ${getInputClasses(
          "company_id"
        )}`}
        defaultValue={companyId}
        onChange={(e) => setCompanyId(e.target.value)}
      >
        <option value="" key="0">
          Select Depo
        </option>
        {companyList?.map((res, key) => {
          return (
            <>
              <option
                key={key + res.Companies__id}
                value={res.Companies__id}
              >
                {res.Companies__Name}
              </option>
            </>
          );
        })}
      </select>
      {formik.touched.company_id && formik.errors.company_id ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{formik.errors.company_id}</div>
        </div>
      ) : null}
    </div> */}
            <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="UserID"
                    type="text"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "email"
                    )}`}
                    name="email"
                    {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
                <input
                    placeholder="Password"
                    type="password"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                        "password"
                    )}`}
                    name="password"
                    {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                ) : null}
            </div>

            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                {/* <Link
        to="/auth/forgot-password"
        className="text-dark-50 text-hover-primary my-3 mr-2"
        id="kt_login_forgot"
      >
        Forget Password
      </Link> */}
                <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={loading}
                    className={`btn font-weight-bold my-3`}
                    style={{
                        margin: "auto",
                        borderRadius: "50px",
                        backgroundColor: "rgb(226 6 18)",
                        color: "#fff",
                        padding: "6px 25px",
                    }}
                >
                    <span>Log In</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
            </div>
        </form>
        {/*end::Form*/}
        {/* <div className="text-center mb-5">
    <h4 className="text-muted font-weight-bold">New User?</h4>{" "}
    <Link className="font-weight-bold" to="/auth/signup">
      Registration Now..
    </Link>
  </div> */}

        {/* <GoogleLogin
    clientId="29603225172-pfhm883kfm8crm526lgpbpa0e92ljnul.apps.googleusercontent.com"
    buttonText="Sign In with Google"
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    className="w-100 justify-content-center"
  /> */}
    </div>;
}

export default injectIntl(connect(null, auth.actions)(Login));

import objectPath from "object-path";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Brand } from "../brand/Brand";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as NotificationAction from "../../../../app/pages/notification/_redux/notificationActions";

export function Aside() {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const { headerFooter, auth } = useSelector(
    ({ notification, auth }) => ({
      headerFooter: notification.headerFooter,
      auth: auth.coreModuleInfo,
    }),
    shallowEqual
  );

  const layoutProps = useMemo(() => {
    return {
      disableScroll:
        objectPath.get(uiService.config, "aside.menu.dropdown") === "true" ||
        false,
      asideClassesFromConfig: uiService.getClasses("aside", true),
      disableAsideSelfDisplay:
        objectPath.get(uiService.config, "aside.self.display") === false,
      headerLogo: uiService.getLogo(),
    };
  }, [uiService]);

  const themeData = useMemo(() => {
    return headerFooter && JSON.parse(headerFooter?.logo_json);
  }, [headerFooter]);

  // useEffect(() => {
  //   dispatch(NotificationAction.getHEaderFooter(auth?.company_id));
  // }, [auth, dispatch]);

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}
        // style={{ width: "250px" }}
      >
        <Brand themeData={themeData} />

        {/* begin::Aside Menu */}
        <div
          id="kt_aside_menu_wrapper"
          className="aside-menu-wrapper flex-column-fluid"
          // style={{ background: "#0f1222" }}
        >
          {layoutProps.disableAsideSelfDisplay && (
            <>
              {/* begin::Header Logo */}
              <div className="header-logo">
                <Link to="">
                  <img alt="logo" src={`${themeData?.mainLogo}`} />
                </Link>
              </div>
              {/* end::Header Logo */}
            </>
          )}
          <AsideMenu
            disableScroll={layoutProps.disableScroll}
            headerFooter={headerFooter}
          />
        </div>
        {/* end::Aside Menu */}
      </div>
      {/* end::Aside */}
    </>
  );
}

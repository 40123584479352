import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  configForEdit: undefined,
  error: null,
  tables: null,
  columns: null,
  approvar: null,
  approvarDropdown: null,
  cards: null,
  eventDropdown: null,
  approvalHistoryInfo: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const delegationSlice = createSlice({
  name: "delegation",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    allConfigFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // getrequisitonById
    configFetched: (state, action) => {
      state.actionsLoading = false;
      state.configForEdit = action.payload.configForEdit;
      state.error = null;
    },

    tablesDropdown: (state, action) => {
      state.actionsLoading = false;
      state.tables = action.payload.tables;
      state.error = null;
    },

    columnsDropdown: (state, action) => {
      state.actionsLoading = false;
      state.columns = action.payload.columns;
      state.error = null;
    },

    approverDropdown: (state, action) => {
      state.actionsLoading = false;
      state.approvarDropdown = action.payload.approvarDropdown;
      state.error = null;
    },

    approverFetch: (state, action) => {
      state.actionsLoading = false;
      state.approvar = action.payload.approvar;
      state.error = null;
    },

    cardFetch: (state, action) => {
      state.actionsLoading = false;
      state.cards = action.payload.cards;
      state.error = null;
    },

    approvalHistoryFetch: (state, action) => {
      state.actionsLoading = false;
      state.approvalHistoryInfo = action.payload.approvalHistoryInfo;
      state.error = null;
    },

    eventDropdownFetch: (state, action) => {
      state.actionsLoading = false;
      state.eventDropdown = action.payload.eventDropdown;
      state.error = null;
    },
  },
});

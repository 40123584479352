import API from "../../../helpers/devApi";

const API_ENDPOINTS = "/hrms_recruitment_requisition";

export function getAllTemplateVariable() {
  return API.get(`/hrms_letter_template/get/variable/for/template`);
}

export function getAllApplicantVariable() {
  return API.get(`/hrms_letter_template/get/variable/for/template/applicant`);
}

export function getAllTemplateType() {
  return API.get(`/hrms_letter_template_type`);
}

export function getAllTemplate() {
  return API.get(`/hrms_letter_template/get/att/public/template`);
}

export function getAllHeaderFooter(companyId) {
  return API.get(`/sys_branchs/${companyId}`);
}

export function getAllCountries() {
  return API.get(`/config_countries`);
}

export function getAllEventList() {
  return API.post(`/hrms_notification_event/geAllEvent`, {});
}

export function getLetterByUserId(payload) {
  return API.post(`/hrms_letter_requests/getLetterData/By/Type`, payload);
}

export function getEventOne(id) {
  return API.get(`/hrms_notification_event/${id}`);
}

export function getLetterList(template_type_id) {
  return API.get(`/hrms_letter_template?template_type_id=${template_type_id}`);
}

export function getRequestedLetterList(payload) {
  return API.post(`/hrms_letter_requests/get/al/request`, payload);
}

export function getLetterOne(id) {
  return API.get(`/hrms_letter_template/${id}`);
}

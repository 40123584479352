/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { getMenuByModule } from "../../../../../app/modules/Auth/redux/authCrud";
import { actions } from "../../../../../app/modules/Auth/redux/authRedux";
import {
  checkIsActive,
  getCurrentUrl,
  toAbsoluteUrl,
} from "../../../../_helpers";
import { v4 as uuidv4 } from "uuid";
 
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  // const getMenuItemActive = (url, hasSubmenu = false) => {
  //   return checkIsActive(location, url)
  //     ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
  //     : "";
  // };
  const getMenuItemActive = (menuItem, hasSubmenu = false) => {
    const currentPath = getCurrentUrl(location);
    const isActive = (item) => {
      if (item.menu_url && currentPath === item.menu_url) {
        return true;
      }
      if (item.children) {
        return item.children.some(isActive);
      }
      return false;
    };
    return isActive(menuItem)
      ? `${!hasSubmenu && "menu-item-active"} menu-item-open`
      : "";
  };
  const { menu, user } = useSelector((state) => state.auth);
 
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.coreModuleInfo,
    }),
    shallowEqual
  );
  const menuType =
    localStorage.getItem("menuType") &&
    JSON.parse(localStorage.getItem("menuType"));
 
  const dispatch = useDispatch();
  const menus = useMemo(() => menu ?? [], [menu ?? []]);
 
  const ordering = [...menus];
 
  useEffect(() => {
    const getMenu = async (menuType) => {
      if (menuType?.id && user?.role_id) {
        await getMenuByModule(menuType?.id, user?.role_id).then((res) => {
          if (res.data.success) {
            dispatch(actions.menu(res?.data?.data));
          }
        });
      }
    };
    if (menuType && authToken) {
      getMenu(menuType);
    }
  }, [menuType?.id, user?.role_id, authToken]);
 
  const renderMenuItem = (item) => {
    const {
      title,
      menu_url,
      children,
      menu_icon_class,
      id,
      parent_menu_id,
      sort_number,
      menu_id,
      ...rest
    } = item;
    const ordering = [...children];
 
    if (item?.children?.length > 0) {
      return (
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            item,
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
          key={uuidv4()}
        >
          <NavLink className="menu-link menu-toggle" to={`${menu_url}` ?? "#"}>
            <span className="svg-icon menu-icon">
              {menu_icon_class ? (
                <i className={`${menu_icon_class} erp_icon`} />
              ) : (
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
              )}
            </span>
            <span className="menu-text">{title}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{title}</span>
                </span>
              </li>
 
              {/*begin::2 Level*/}
              {ordering &&
                ordering?.length > 0 &&
                ordering
                  ?.sort((a, b) => a?.menu_order - b?.menu_order)
                  ?.map((submenu) => renderMenuItem(submenu))}
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
      );
    }
 
    return (
      <li
        className={`menu-item ${getMenuItemActive(item, false)}`}
        aria-haspopup="true"
        key={uuidv4()}
      >
        <NavLink className="menu-link" to={`${menu_url}`}>
          <span className="svg-icon menu-icon">
            {menu_icon_class ? (
              <span className="svg-icon menu-icon">
                <i className={`${menu_icon_class} erp_icon`} />
              </span>
            ) : (
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
            )}
          </span>
          <span className="menu-text">{title}</span>
        </NavLink>
      </li>
    );
  };
 
  return (
    <>
      {/* begin::Menu Nav  */}
 
      <ul className={`menu-nav ${layoutProps?.ulClasses}`}>
        {ordering &&
          ordering?.length > 0 &&
          ordering
            ?.sort((a, b) => a?.menu_order - b?.menu_order)
            ?.map((menu, i) => renderMenuItem(menu))}
      </ul>
    </>
  );
}
import Axios from "axios";
import API from "../../../helpers/devApi";
import ERP_API from "../../../helpers/erpDevApi";
export const LOGIN_URL = `/auth/login-panel`;
export const ERP_LOGIN_URL = `/auth/login`;
export const REGISTER_URL = `/auth/signup`;
export const REQUEST_PASSWORD_URL = `/auth/forgot-password`;
export const ME_URL = `/auth/profile`;
export const MODULE_URL = `/modulechanger/authorized-list`;
// export const MENU_URL = `/menu/menuPrivilege`;
export const MENU_URL = `/sys_menus/role-wise-menu?module_id=22`;
export const FEATURE_PERMISSIONS = `/hrms-feature-permission/user-wise-feature-permission?module_id=22`;

export async function login(email, password) {
  return await API.post(LOGIN_URL, { email, password });
}
export async function Erplogin(email, password) {
  return await ERP_API.post(ERP_LOGIN_URL, {user: email, password });
}

export function register(email, user_name, password) {
  return API.post(REGISTER_URL, { email, user_name, password });
}

export async function getModuleList() {
  return await ERP_API.get(MODULE_URL);
}

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return await ERP_API.get(`${ME_URL}`);
}

export async function updateProfile(id, payload) {
  // Authorization head should be fulfilled in interceptor.
  return await API.patch(`${ME_URL}/${id}`, payload);
}

export async function getMenuByModule(menuType, roleId) {
  const authInfo = localStorage.getItem("AUTH") && localStorage.getItem("AUTH");
  // Authorization head should be fulfilled in interceptor.
  const response = (await API.get(MENU_URL)).data;
  response.statusCode = 201
  // debugger
  // const response = await ERP_API.post(
  //   // `http://192.168.20.14:3061/api/v1${MENU_URL}`,
  //   `${MENU_URL}`,
  //   {
  //     role_id: roleId,
  //     module_id: menuType,
  //   }
  //   // {
  //   //   headers: {
  //   //     Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJ1c2VyX25hbWUiOiJhZG1pbiIsImNvbXBhbnlfaWQiOjEsImJyYW5jaF9pZCI6MSwiYnJhbmNoX3R5cGUiOiJJbnN0aXR1dGUiLCJkZXBhcnRtZW50X2lkIjo3LCJkaXZpc2lvbl9pZCI6MSwiZW1haWwiOiJyZWFzaGVkLmlzbGFtQGFwc2lzc29sdXRpb25zLmNvbSIsInBhcmVudF9icmFuY2hfaWQiOiIiLCJpYXQiOjE2OTIyNjYxOTgsImV4cCI6MTY5MjI3MzM5OH0.ydBhLVH8cA-Y0UgAuIN-81a3awpxs59g7MMEUjs1P2s`,
  //   //   },
  //   // }
  // )
  return response;
}

export async function getFeturePermission() {
  const response = (await API.get(FEATURE_PERMISSIONS)).data
  return response;
}

import React from "react";
import { useField } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { Select, Space } from "antd";
import MultiSelectDropdown from "../../dropdowns/MultiSelectDropdown";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid-select");
  }

  if (touched && !errors) {
    classes.push("is-valid-select");
  }

  return classes.join(" ");
};

export function SelectInput({
  label,
  withFeedbackLabel = false,
  customFeedbackLabel,
  children,
  errors,
  touched,
  onChange = false,
  isRequired = false,
  defaultValue = null,
  value = "",
  options = [],
  showSearch = true,
  disabled = false,
  mode = "single",
  allowClear = true,
  placeholder = "Option",
  ...props
}) {
  switch (mode) {
    case "single":
      return (
        <div className="form-group">
          {label && (
            <label className={isRequired ? "required" : ""}>{label}</label>
          )}
          <Select
            style={{
              width: "100%",
              minHeight: "42px",
            }}
            mode={mode}
            status={errors ? "error" : null}
            onChange={onChange}
            options={options}
            className={getFieldCSSClasses(touched, errors)}
            value={!value || value == "" ? undefined : value}
            disabled={disabled}
            placeholder={`Select ${label}`}
            maxTagCount="responsive"
            allowClear={allowClear}
            showSearch={showSearch}
            filterOption={(inputValue, option) =>
              option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !==
              -1
            }
            {...props}
          />
          {withFeedbackLabel && (
            <FieldFeedbackLabel
              erros={errors}
              touched={touched}
              label={label}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </div>
      );

    case "tags":
      return (
        <div className="form-group">
          {label && (
            <label className={isRequired ? "required" : ""}>{label}</label>
          )}
          <Select
            style={{
              width: "100%",
              minHeight: "42px",
            }}
            mode={mode}
            status={errors ? "error" : null}
            onChange={onChange}
            options={options}
            className={getFieldCSSClasses(touched, errors)}
            value={!value || value == "" ? undefined : value}
            disabled={disabled}
            placeholder={`Select ${label}`}
            maxTagCount="responsive"
            allowClear={allowClear}
            showSearch={showSearch}
            filterOption={(inputValue, option) =>
              option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            {...props}
          />
          {withFeedbackLabel && (
            <FieldFeedbackLabel
              erros={errors}
              touched={touched}
              label={label}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </div>
      );

    case "multiple":
      return (
        <div>
          {label && (
            <label className={isRequired ? "required" : ""}>{label}</label>
          )}
          <MultiSelectDropdown
            onChange={onChange}
            value={value ?? []}
            disabled={disabled}
            options={options ?? []}
            placeholder={label}
          />
        </div>
      );

    default:
      break;
  }
}

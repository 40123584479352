import React, { useEffect } from "react";

function DynamicCSSLoader({ cssFilePath }) {
  useEffect(() => {
    const link = document.createElement("link");
    link.href = cssFilePath;
    link.rel = "stylesheet";
    link.type = "text/css";
    document.head.appendChild(link);

    return () => {
      // Cleanup: Remove the dynamically added <link> element when the component unmounts
      document.head.removeChild(link);
    };
  }, [cssFilePath]);

  return null; // This component doesn't render anything
}

export default DynamicCSSLoader;

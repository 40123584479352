import jwtDecode from "jwt-decode";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { getModuleList, getUserByToken } from "./authCrud";
import * as auth from "./authRedux";

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("user");
    const user =
      authToken && authToken.access_token
        ? jwtDecode(authToken.access_token)
        : null;
    const requestUser = async () => {
      try {
        if (!didRequest.current && user && Object.keys(user).length > 0) {
          const { data } = await getUserByToken();
          if (!data.error) {
            localStorage.setItem("user", JSON.stringify(data));
            dispatch(props.fulfillUser(data));
            // localStorage.setItem("lang", data?.data?.language);
            const coreModuleToken =
              localStorage.getItem("CORE_MODULE_AUTH") &&
              localStorage.getItem("CORE_MODULE_AUTH");
            if (coreModuleToken) {
              const coreModuleInfo = jwtDecode(coreModuleToken);
              coreModuleInfo.token = coreModuleToken;
              await dispatch(props.coreModuleLogin(coreModuleInfo));
            }
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };
    if (authToken || token) {
      requestUser();
      localStorage.setItem("AUTH", JSON.stringify(authToken));
      setShowSplashScreen(false);

      return;
    } else {
      dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);
      // setTimeout(() => {
      //   window.location.href = `${process.env.REACT_APP_ERP_FE}`;
      // }, 2000);
    }
    // eslint-disable-next-line
  }, [authToken]);

  // useLayoutEffect(() => {

  // }, [coreModuleToken]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);

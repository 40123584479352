import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import AllDashboardPages from "./pages/dashboard";
import Profile from "./pages/profile/Profile";
import ChangePassword from "./pages/changepassword/ChangePassword";
import About from "./pages/about";


// pages lists
const UserSettingsPage = lazy(() => import("./pages/user-settings"));
const UserRolePage = lazy(() => import("./pages/user-settings/roles"));
const AdminSettingsPage = lazy(() => import("./pages/admin-settings"));
const MasterGridPage = lazy(() => import("./pages/master-grid"));
const RecruitmentPage = lazy(() => import("./pages/recruitement"));
const RequisitionPage = lazy(() => import("./pages/requisition"));
const JobDescriptionPage = lazy(() => import("./pages/job-description"));
const ApplicantPage = lazy(() => import("./pages/applicant"));
const RulesPage = lazy(() => import("./pages/rules"));
const ShiftPage = lazy(() => import("./pages/shift"));
const OffDayPage = lazy(() => import("./pages/off-day"));
const LeavePage = lazy(() => import("./pages/leave-management"));
const HolidayPage = lazy(() => import("./pages/holidays"));
const OverTimePage = lazy(() => import("./pages/over-time"));
const DelegationPages = lazy(() => import("./pages/delegation"));
const Employee = lazy(() => import("./pages/employee"));
const FeaturePermissions = lazy(() => import("./pages/permission"));
const Organogram = lazy(() => import("./pages/organogram"));
const weekendPage = lazy(() => import("./pages/weekend"));
const attendancePage = lazy(() => import("./pages/attendance"));
const WageManagement = lazy(() => import("./pages/wage-management"));
const Policy = lazy(() => import("./pages/policy"));
const Notice = lazy(() => import("./pages/notice"));
const PerformanceManagement = lazy(() => import("./pages/performance"));
const TaxManagement = lazy(() => import("./pages/tax-management"));
const LoanManagement = lazy(() => import("./pages/loan"));
const LetterManagement = lazy(() => import("./pages/letter-management"));
const NotificationPage = lazy(() => import("./pages/notification"));
const SettingsPage = lazy(() => import("./pages/settings"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <Route exact path="/dashboard" component={AllDashboardPages} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/profile" component={Profile} />
        <Route path="/hrms/user-setting" component={UserSettingsPage} />
        <Route path="/admin-settings" component={AdminSettingsPage} />
        <Route path="/employee" component={Employee} />
        <Route path="/policy" component={Policy} />
        <Route path="/notice" component={Notice} />
        <Route path="/about" component={About} />
        <Route path="/requisitons" component={RequisitionPage} />
        <Route path="/recruitment" component={RecruitmentPage} />
        <Route path="/job-description" component={JobDescriptionPage} />
        <Route path="/applicant" component={ApplicantPage} />
        <Route path="/rules" component={RulesPage} />
        <Route path="/shift" component={ShiftPage} />
        <Route path="/off-day" component={OffDayPage} />
        <Route path="/leave" component={LeavePage} />
        <Route path="/holidays" component={HolidayPage} />
        <Route path="/over-time" component={OverTimePage} />
        <Route path="/delegation" component={DelegationPages} />
        <Route path="/feature-permissions" component={FeaturePermissions} />
        <Route path="/organogram" component={Organogram} />
        <Route path="/weekend" component={weekendPage} />
        <Route path="/payroll" component={WageManagement} />
        <Route path="/attendance" component={attendancePage} />
        <Route path="/performance" component={PerformanceManagement} />
        <Route path="/tax" component={TaxManagement} />
        <Route path="/loan" component={LoanManagement} />
        <Route path="/letter" component={LetterManagement} />
        <Route path="/notification" component={NotificationPage} />
        <Route path="/settings" component={SettingsPage} />

        <Route path="*/masterdata" component={MasterGridPage} />

        {/* <Redirect to="error/error-v1" /> */}
      </Switch>
    </Suspense>
  );
}

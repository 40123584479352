/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Toaster } from "react-hot-toast";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen } from "../_metronic/layout";
import { Routes } from "../app/Routes";
import API from "../app/helpers/devApi";
import { apsisDecrypt } from "./helpers/apsisEncryption";
import AuthInit from "./modules/Auth/redux/AuthInit";
import { actions } from "./modules/Auth/redux/authRedux";
import def from "../index.scss";
import cus from "../custom.scss";
import "../apsis.scss";
import DynamicCSSLoader from "./helpers/DynamicCSSLoader";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import useLocalStorage from "use-local-storage";
import jwtDecode from "jwt-decode";
import "../responsive.scss";
import { Helmet } from "react-helmet";
import * as NotificationAction from "./pages/notification/_redux/notificationActions";
const secretPass = "XkhZG4fW2t2W";


export default function App({ store, basename, persistor }) {
  const [userJSON, setUserJSON] = useState();
  const dispatch = useDispatch();
  const handle = useFullScreenHandle();
  const { headerFooter } = useSelector(
    ({ notification }) => ({
      headerFooter: notification.headerFooter, 
    }),
    shallowEqual
  );
  const defaultLight = window.matchMedia("(prefers-color-scheme: light)")
    .matches;

  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);
  //   const user = params.get("user");
  //   if (user) {
  //     console.log(user);
  //     let decData = apsisDecrypt(user);
  //     setUserJSON(JSON.parse(decData));
  //   }
  // }, []);

  const [fakeLogin, setFakeLogin] = useState();
  const fetchData = async (val) => {
    await API.post("/auth/login", {
      email: val,
      password: "fromERP",
    }).then((res) => {
      setFakeLogin(res.data?.data?.access_token);

      localStorage.setItem("CORE_MODULE_AUTH", res.data?.data?.access_token);
    });
  };

  const themeData = useMemo(() => {
    return headerFooter && JSON.parse(headerFooter?.logo_json);
  }, [headerFooter]);

  useEffect(() => {
    dispatch(NotificationAction.getHEaderFooter(process.env.REACT_APP_COMPANY_ID));
  }, [ dispatch]);

  // useEffect(() => {
  //   if (userJSON) {
  //     dispatch(actions.login(userJSON));
  //     fetchData(userJSON.access_token);
  //     localStorage.setItem("fromErp", true);
  //   }
  // }, [userJSON, dispatch]);

  // useEffect(() => {
  //   if (fakeLogin) {
  //     const coreModuleInfo = jwtDecode(fakeLogin);
  //     coreModuleInfo.token = fakeLogin;
  //     dispatch(actions.coreModuleLogin(coreModuleInfo));
  //   }
  // }, [fakeLogin, dispatch]);

  // console.log(userJSON.user_name);
  return (
    <div data-theme={"light"}>
      <Helmet>
        <title>{themeData?.title}</title>
        <link rel="icon" type="image/x-icon" href={themeData?.fav} />
      </Helmet>
      <FullScreen handle={handle}>
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              {/* Render routes with provided `Layout`. */}
              <AuthInit>
                <Toaster />
                <Routes handle={handle} />
              </AuthInit>
            </I18nProvider>
          </BrowserRouter>
        </React.Suspense>
      </FullScreen>
    </div>
  );
}
